/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import BookingsTable from "../components/BookingsTable";
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import { Link } from "react-router-dom";
import { Timestamp, collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "..";
import CircularLoader from "../components/CircularLoader";
import { useTranslation } from "react-i18next";
import AppContext from "../AppContext";
import { helpers } from "../helpers";
import RoleLabel from "../components/RoleLabel";
import StatusLabel from "../components/StatusLabel";


const Home = () => {
    const { t } = useTranslation();

    useDocumentTitle("Home");

    const { user } = useContext(AppContext);

    const [stats, setStats] = useState({});
    const fetchStats = async () => {
        let stats = {};
        //fetch stats from firebase
        const propertiesRef = collection(db, 'properties');
        const usersRef = collection(db, 'users');
        const bookingsRef = collection(db, 'bookings');
        const postsRef = collection(db, 'posts');

        await getDocs(propertiesRef).then((snapshot) => {
            stats.properties = snapshot.size;
        });
        await getDocs(usersRef).then((snapshot) => {
            stats.users = snapshot.size;
        });
        // online where last_logged_in is less than 5 hours ago
        const fiveHoursAgo = Timestamp.fromMillis(Date.now() - 1000 * 60 * 60 * 5);
        const q = query(usersRef, orderBy('last_logged_in', 'desc'), where('last_logged_in', '>', fiveHoursAgo));
        await getDocs(q).then((snapshot) => {
            stats.online = snapshot.size;
        });
        await getDocs(bookingsRef).then((snapshot) => {
            stats.bookings = snapshot.size;
        });
        await getDocs(postsRef).then((snapshot) => {
            stats.posts = snapshot.size;
        });
        

        setStats(stats);
    }

    const [bookings, setBookings] = useState([]);
    const [bookingsLoading, setBookingsLoading] = useState(true);
    const fetchBookings = async () => {
        setBookingsLoading(true);
        let bookings = [];
        //fetch bookings from firebase
        const bookingsRef = collection(db, 'bookings');
        const q = query(bookingsRef, orderBy('created_at', 'desc'));
        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                bookings.push({ ...doc.data(), id: doc.id });
            });
        });
        setBookings(bookings);
        setBookingsLoading(false);
    }



    useEffect(() => {
        fetchStats();
        fetchBookings();
    }, []);



    const onTableUpdated = (booking) => {
        fetchBookings();
    }



    return (
        <>
            <MainTemplate title={t('dashboard')}>


                {/* <!-- Cards --> */}
                <div className={`grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4 ${!helpers.isAdministrator(user) && "grid-cols-2 md:grid-cols-2 xl:grid-cols-2"}`}>
                    {/* <!-- Card --> */}
                    <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 w-full">
                        <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100 dark:bg-orange-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon w-5 h-5 icon-tabler icon-tabler-home-eco" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M20 11l-8 -8l-9 9h2v7a2 2 0 0 0 2 2h5" />
                                <path d="M9 21v-6a2 2 0 0 1 2 -2h2c.325 0 .631 .077 .902 .215" />
                                <path d="M16 22s0 -2 3 -4" />
                                <path d="M19 21a3 3 0 0 1 0 -6h3v3a3 3 0 0 1 -3 3z" />
                            </svg>
                        </div>
                        <div>
                            <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                <Link to="/admin/properties">{t('properties')}</Link>
                            </p>
                            <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                                {stats.properties ?? (<CircularLoader />)}
                            </p>
                        </div>
                    </div>
                    {/* <!-- Card --> */}
                    <div className={`flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 ${!helpers.isHost(user) && "hidden"}`}>
                        <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100 dark:bg-orange-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon w-5 h-5 icon-tabler icon-tabler-user-dollar" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="currentColor" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h3" />
                                <path d="M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" />
                                <path d="M19 21v1m0 -8v1" />
                            </svg>
                        </div>
                        <div>
                            <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                <Link to="/admin/profile">{t('account')}</Link>
                            </p>
                            <p className="text-lg font-semibold text-gray-700 dark:text-gray-200 flex flex-row gap-3">
                                <RoleLabel role={user?.role} /> <StatusLabel status={user?.status} />
                            </p>
                        </div>
                    </div>
                    {/* <!-- Card --> */}
                    <div className={`flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 ${!helpers.isAdministrator(user) && "hidden"}`}>
                        <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon w-5 h-5 icon-tabler icon-tabler-user-dollar" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="currentColor" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h3" />
                                <path d="M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" />
                                <path d="M19 21v1m0 -8v1" />
                            </svg>
                        </div>
                        <div className="flex flex-row gap-4 items-center justify-between mr-3 w-full">
                            <div className="flex flex-col items-start">
                                <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                    <Link to="/admin/users">{t('users')}</Link>
                                </p>
                                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {stats.users ?? (<CircularLoader />)}
                                </p>
                            </div>
                            <div className="flex flex-col items-center">
                                <p className="mb-2 text-sm text-gray-600 dark:text-gray-400 font-bold">
                                    {t('online')}
                                </p>
                                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                                    {stats.online ?? (<CircularLoader />)}
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Card --> */}
                    <div className={`flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 ${!helpers.isAdministrator(user) && "hidden"}`}>
                        <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon w-5 h-5 icon-tabler icon-tabler-receipt" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2" />
                            </svg>
                        </div>
                        <div>
                            <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                <Link to="/admin/bookings">{t('bookings')}</Link>
                            </p>
                            <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                                {stats.bookings ?? (<CircularLoader />)}
                            </p>
                        </div>
                    </div>
                    {/* <!-- Card --> */}
                    <div className={`flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 ${!helpers.isAdministrator(user) && "hidden"}`}>
                        <div className="p-3 mr-4 text-teal-500 bg-teal-100 rounded-full dark:text-teal-100 dark:bg-teal-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon w-5 h-5 icon-tabler icon-tabler-news" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
                                <path d="M8 8l4 0" />
                                <path d="M8 12l4 0" />
                                <path d="M8 16l4 0" />
                            </svg>
                        </div>
                        <div>
                            <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                <Link to="/admin/posts">{t('posts')}</Link>
                            </p>
                            <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                                {stats.posts ?? (<CircularLoader />)}
                            </p>
                        </div>
                    </div>
                </div>


                {
                    helpers.isAdministrator(user) && (
                        <BookingsTable bookings={bookings} loading={bookingsLoading} onTableUpdated={onTableUpdated} />
                    )
                }






            </MainTemplate>
        </>
    );
};

export default Home;
