import React, { useContext } from "react";
import CircularLoader from "./CircularLoader";
import { confirm } from "react-confirm-box";
import { toast } from "react-toastify";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "..";
import { useTranslation } from "react-i18next";
import AppContext from "../AppContext";
import { helpers } from "../helpers";


const LogsTable = ({ logs, onTableUpdated, loading = false }) => {

    const {t} = useTranslation();

    const {user} = useContext(AppContext);

    // const [showModal, setShowModal] = useState(false);
    // const [log, setLog] = useState({});

    // const onModalUpdated = (log) => {
    //     onTableUpdated(log);
    // }

    const deleteLog = async (log) => {

        if (!helpers.isSuperAdmin(user)) {
            toast.error("You are not authorized to perform this action");
            return;
        }

        //confirm delete
        const proceed = await confirm(`${t('are-you-sure-you-want-to-delete')}?`);
        if (!proceed) return;

        const loading = toast.loading(t('please-wait'));

        const logRef = doc(db, "logs", log.id);

        await deleteDoc(logRef).then(() => {
            onTableUpdated(null);
            toast.success(t('deleted-successfully'));
        }
        ).catch((error) => {
            toast.error(t('failed-to-delete'));
        }
        );

        toast.dismiss(loading);

    }

    return (
        <>

            {/* <!-- New Table --> */}
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                <div className="w-full overflow-x-auto">
                    <table className="w-full whitespace-no-wrap" id="amenities-table">
                        <thead>
                            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                                <th className="px-4 py-3">{t('user')}</th>
                                <th className="px-4 py-3">{t('content')}</th>
                                <th className="px-4 py-3">{t('date')}</th>
                                <th className="px-4 py-3 text-right">{t('action')}</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        {
                                loading && (
                                    <tr className="text-gray-700 dark:text-gray-400">
                                        <td className="px-4 py-3 text-sm" colSpan="3">
                                            <div className="flex justify-center items-center">
                                                <CircularLoader />
                                                <span>{t('loading')}</span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }

                            {
                                (logs.length > 0) && logs.map((log) => (
                                    <tr key={log.id} className="text-gray-700 dark:text-gray-400" id="aW8VVjxUdUTnbSgTZ7g1">
                                        <td className="px-4 py-3 text-sm text-left font-bold">
                                            <p id="row-content">{log?.user?.full_name || "Unknown"}</p>
                                        </td>
                                        <td className="px-4 py-3">
                                            <div className="flex items-center text-sm">

                                                <div>
                                                    <p id="row-title" className="" dangerouslySetInnerHTML={{ __html: log.message }}></p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-4 py-3 text-sm">
                                            {log.created_at.toDate().toLocaleDateString()}
                                        </td>
                                        <td className="px-4 py-3 text-sm text-right">
                                            <button onClick={() => deleteLog(log)} className="inline text-center bg-red-700 rounded text-white p-1 hover:opacity-80 ml-2">
                                                <svg id="icon" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <desc>Download more icon variants from https://tabler-icons.io/i/trash</desc>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <line x1="4" y1="7" x2="20" y2="7"></line>
                                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                </svg>
                                                <svg id="loading" className="hidden animate-spin h-4 w-4 icon icon-tabler text-white" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }

                            {
                                (logs.length === 0 && !loading) && (
                                    <tr className="text-gray-700 dark:text-gray-400">
                                        <td className="px-4 py-3 text-sm text-center" colSpan="4">
                                            <p id="row-content">{t('no-logs-available')}</p>
                                        </td>
                                    </tr>
                                )
                            }

                        </tbody >
                    </table>
                </div>
            </div>

        </>
    );
}


export default LogsTable;