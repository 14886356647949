import { useContext, useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import Currency from "../../components/Currency";
import { helpers } from "../../helpers";
import { db } from "../..";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import AppContext from "../../AppContext";


const BookingModal = ({ showModal, setShowModal, booking, setBooking, onModalUpdated }) => {

    const {t} = useTranslation();

    const {user} = useContext(AppContext);

    const [bookingData, setBookingData] = useState(booking ?? {});
    const [notes, setNotes] = useState(bookingData.notes ?? []);

    useEffect(() => {
        setBookingData(booking);
        setNotes(bookingData.notes ?? []);
    }, [booking, bookingData.notes]);

    const onInputChanged = (e) => {
        setBookingData({ ...bookingData, [e.target.name]: e.target.value });
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();

        const loading = toast.loading(t('updating-booking'));

        //remove other fields except status and notes
        let formData = {
            status: bookingData.status,
            notes: notes,
        };

        //update updated_at
        formData.updated_at = Timestamp.now();

        //update property status as taken if order status is accepted
        if (formData.status === "accepted") {

            if (bookingData.property_id) {
                //check if property is already taken and user is the not the owner, before updating
                var propertyRef = doc(db, "properties", bookingData.property_id);
                var propertyDoc = await getDoc(propertyRef);
                if (propertyDoc.exists()) {
                    if (propertyDoc.data().status === "taken" && propertyDoc.data().user_id !== bookingData.user_id) {
                        toast.dismiss(loading);
                        toast.error(t('property-is-already-taken'), { loadingId: loading });
                        return;
                    }
                }
            }
        }

        //update booking
        await updateDoc(doc(db, "bookings", bookingData.id), formData);

        //update property status as taken if order status is accepted
        if (bookingData.status === "accepted") {
            if (bookingData.property_id) {
                await updateDoc(doc(db, "properties", bookingData.property_id), { status: "taken" });
            }
        }
        //update property status as publish if order status is completed
        else if (bookingData.status === "completed") {
            if (bookingData.property_id) {
                await updateDoc(doc(db, "properties", bookingData.property_id), { status: "publish" });
            }
        }

        //push notification and email to user fcm_token (that there is an update on booking)
        if (bookingData.user_id) {
            //get user fcm_token
            var userRef = doc(db, "users", bookingData.user_id);
            var userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                var userData = userDoc.data();
                //push notification to user fcm_token
                if (userData.fcm_token) {
                    //send notification
                    await helpers.pushNotificationToToken(userData.fcm_token, {
                        title: `${t('booking')} #${bookingData.id} ${t('updated-0')}`,
                        body:t('your-booking-has-been-updated'),
                    });
                }
                //send email
                if (userData.email) {
                    //send email
                    await helpers.sendMail(
                        userData.email,
                        t('booking') + " # " + bookingData.id + " Updated", 
                        `
                            <p>t('your-booking-has-been-updated')</p>
                            <p>${t('status')}: <strong>${helpers.capitalizeFirstLetter(bookingData.status)}</strong></p>
                            <p>${t('booking')} # ${bookingData.id}</p>
                            <p>${t('property')}: ${bookingData.property.name}</p>
                            <p>${t('property-address')}: ${bookingData.property.address}</p>
                            <p>${t('property-offer-type')}: ${helpers.capitalizeFirstLetter(bookingData.property.offer_type ?? "Sale")}</p>
                            <p>${t('property-type')}: ${helpers.capitalizeFirstLetter(bookingData.property.type.name ?? "-")}</p>
                            <p>${t('property-price')}: <strong>GNF${helpers.money(bookingData.property.price)}</strong></p>
                        `
                        );
                }
            }
        }



        //close modal
        setShowModal(false);

        //remove loading
        toast.dismiss(loading);

        toast.success(t('booking-updated-successfully'), { loadingId: loading });

        onModalUpdated(formData);

        helpers.log(user, "update", `booking <b>(${bookingData.id})</b> updated`);
    }



    return (
        <>
            <SkyeModal title={bookingData.id ? t('booking') : t('new-booking')} size="max-w-7xl" flex={false} showModal={showModal} setShowModal={setShowModal}>
                <form className="space-y-6" id="booking-edit-form" onSubmit={onFormSubmit} method="POST" encType="multipart/form-data">
                    {/*body*/}
                    <div className="relative p-6 flex-auto">


                        {/* body */}

                        {/* status */}
                        <div className="col-span-6 sm:col-span-3 mb-4">
                            <div className="grid grid-cols-2 items-center">
                                <label htmlFor="status" className="block text-sm font-bold text-black   ">
                                    {t('status')}:
                                </label>
                                <select
                                    name='status'
                                    value={bookingData.status || ""}
                                    onChange={onInputChanged}
                                    id="status" className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="pending">{t('pending')}</option>
                                    <option value="accepted">{t('accepted')}</option>
                                    <option value="completed">{t('completed')}</option>
                                    <option value="cancelled">{t('cancelled')}</option>
                                </select>
                            </div>
                        </div>

                        {/* house property info and user info grid */}
                        <div className="grid grid-cols-2 gap-4 px-5">
                            {/* house property info */}
                            <div className="col-span-1 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg p-4">
                                {/* header */}
                                <div className="flex items-center justify-between">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">{t('property')}</h3>
                                </div>
                                {/* body */}
                                {
                                    bookingData.property &&
                                    (
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                                                {/* property name */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('name-0')}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100">{bookingData.property.name}</div>
                                                    </td>
                                                </tr>
                                                {/* property address */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('address')}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100">{bookingData.property.address}</div>
                                                    </td>
                                                </tr>
                                                {/* offer type */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('offer-type')}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100">{helpers.capitalizeFirstLetter(bookingData.property.offer_type ?? t('sale'))}</div>
                                                    </td>
                                                </tr>
                                                {/* property type */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('property-type')}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100">{helpers.capitalizeFirstLetter(bookingData.property.type.name ?? "-")}</div>
                                                    </td>
                                                </tr>
                                                {/* property price */}
                                                {
                                                    bookingData.property.offer_type === "sale" && (
                                                        <tr>
                                                            <td className="px-3 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('price')}</div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900 dark:text-gray-100"><Currency />{helpers.money(bookingData.property.price)}</div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    bookingData.property.offer_type === "rent" && (
                                                        <tr>
                                                            <td className="px-3 py-4 whitespace-nowrap" colSpan={2}>
                                                                <div className="grid grid-cols-2 gap-x-2">
                                                                    <div>
                                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('monthly')}</div>
                                                                        <div className="text-sm text-gray-900 dark:text-gray-100"><Currency />{helpers.money(bookingData.property.montly_price ?? 0)}</div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('daily')}</div>
                                                                        <div className="text-sm text-gray-900 dark:text-gray-100"><Currency />{helpers.money(bookingData.property.daily_price ?? 0)}</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {/* property area */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('surface-area')}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100">{bookingData.property.surface_area ?? "-"} sqft</div>
                                                    </td>
                                                </tr>
                                                {/* property bedrooms, bathrooms and beds */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap" colSpan={2}>
                                                        <div className="grid grid-cols-3 gap-x-2">
                                                            <div>
                                                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('bedrooms')}</div>
                                                                <div className="text-sm text-gray-900 dark:text-gray-100">{bookingData.property.bedrooms ?? "-"}</div>
                                                            </div>
                                                            <div>
                                                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('bathrooms')}</div>
                                                                <div className="text-sm text-gray-900 dark:text-gray-100">{bookingData.property.bathrooms ?? "-"}</div>
                                                            </div>
                                                            <div>
                                                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('beds')}</div>
                                                                <div className="text-sm text-gray-900 dark:text-gray-100">{bookingData.property.beds ?? "-"}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* property facilities */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('facilities')}</div>
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 pr-5">
                                                            {
                                                                bookingData.property.facilities && bookingData.property.facilities.map((facility, index) => (
                                                                    <span key={index} className="items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-1 inline-block my-0.5">
                                                                        {facility.name}
                                                                    </span>
                                                                ))
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* property amenities */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('amenities')}</div>
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100">
                                                            {
                                                                bookingData.property.amenities && bookingData.property.amenities.map((amenity, index) => (
                                                                    <span key={index} className="items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-1 inline-block my-0.5">
                                                                        {amenity.name}
                                                                    </span>
                                                                ))
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* property images */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('images')}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap overflow-auto">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100">
                                                            {
                                                                bookingData.property.images && bookingData.property.images.map((image, index) => (
                                                                    <img key={index} src={image} alt="" className="w-20 h-20 object-cover rounded-lg inline-block mr-1" />
                                                                ))
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )
                                }
                            </div>
                            {/* user info */}
                            <div className="col-span-1 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg p-4">
                                {/* header */}
                                <div className="flex items-center justify-between">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">{t('booking-info')}</h3>
                                </div>
                                {/* body */}
                                {
                                    bookingData.user &&
                                    (
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                                                {/* avatar */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold"></div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100">
                                                            <img src={helpers.getFirstImage(bookingData.user.avatar, "avatar", bookingData.user.full_name ?? "A")} alt="" className="w-20 h-20 object-cover rounded-lg inline-block mr-1" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* user name */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('name-0')}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100">{bookingData.user.full_name ?? "-"}</div>
                                                    </td>
                                                </tr>
                                                {/* user email */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('email-0')}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100">{bookingData.user.email ?? "-"}</div>
                                                    </td>
                                                </tr>
                                                {/* user phone */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('account-phone')}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100">{bookingData.user.phone ?? "-"}</div>
                                                    </td>
                                                </tr>
                                                {/* other phone */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('other-phone')}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100">{bookingData.user.other_phone ?? "-"}</div>
                                                    </td>
                                                </tr>
                                                {/* user address */}
                                                <tr>
                                                    <td className="px-3 py-4 whitespace-nowrap" colSpan={2}>
                                                        <div className="grid grid-cols-2 gap-x-2">
                                                            <div>
                                                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('address')}</div>
                                                                <div className="text-sm text-gray-900 dark:text-gray-100">{bookingData.user.address ?? "-"}</div>
                                                            </div>
                                                            <div>
                                                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('city')}</div>
                                                                <div className="text-sm text-gray-900 dark:text-gray-100">{bookingData.user.city ?? "-"}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )
                                }
                            </div>
                        </div>

                        {/* total table and notes */}
                        <div className="grid grid-cols-2 gap-4 px-5 pt-4">
                            <div className="flex flex-col mt-4">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                                                    {/* <tr>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('subtotal')}</div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900 dark:text-gray-100 font-bold text-right"><Currency />{helpers.money(bookingData.price ?? 0)}</div>
                                                        </td>
                                                    </tr> */}
                                                    {/* <tr>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">Tax</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900 dark:text-gray-100 font-bold  text-right"><Currency />{bookingData.tax}</div>
                                                    </td>
                                                </tr> */}
                                                    <tr>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">{t('total')}</div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900 dark:text-gray-100 font-bold  text-right"><Currency />{helpers.money(bookingData.price ?? 0)}</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg p-4">
                                {/* header */}
                                <div className="flex items-center justify-between">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">{t('notes')}</h3>
                                </div>
                                {/* body */}
                                <div className="mt-4">
                                    <div className="grid grid-cols-1 gap-4">
                                        {
                                            notes && notes.map((note, index) => (
                                                <div key={index} className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-2 rounded-lg">
                                                    <div className="text-sm text-gray-900 dark:text-gray-100">{note}</div>
                                                    <button type="button" onClick={() => {
                                                        setNotes(notes.filter((n, i) => i !== index));
                                                    }} className="text-sm text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-600">
                                                        {t('remove')}
                                                    </button>
                                                </div>
                                            ))
                                        }
                                        {/* add note button */}
                                        <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-2 rounded-lg">
                                            <input type="text" name="note" id="note" className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('add-note')} onChange={(e) => {
                                                setBookingData({ ...bookingData, note: e.target.value });
                                            }} />
                                            <button type="button" onClick={() => {
                                                if (bookingData.note) {
                                                    setNotes([...notes, bookingData.note]);
                                                    setBookingData({ ...bookingData, note: "" });
                                                }
                                            }} className="text-sm text-green-500 hover:text-green-700 dark:text-green-400 dark:hover:text-green-600 px-4">
                                                {t('add')}
                                            </button>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>





                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                        <button type="submit" className="bg-black hover:bg-black text-white font-semibold py-2 px-20 text-xl rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2">
                            {t('update-booking')}
                        </button>
                    </div>
                </form>
            </SkyeModal>
        </>
    );
}

export default BookingModal;