import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import useToken from "./useToken";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../index";

const useAuth = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const { token, login: tokenLogin, logout: tokenLogout } = useToken();
  const [user, setUser] = useState(cookies.user ?? null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
    return () => {
      // Cleanup code goes here (if needed)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //from firebase
  const fetchData = async () => {
    setLoading(true);
    try {
      // const data = await db.collection("users").doc(user.id).get();
      const docRef = doc(db, "users", token);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        var data = { ...docSnap.data(), id: docSnap.id };
        //if is admin or super admin or host
        if (data.role === "admin" || data.role === "super-admin" || data.role === "host") {
          setUser(data);
        } else {
          setUser(null);
        }
      } else {
        setUser(null);
      }
    } catch (error) {
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  return {
    user,
    token,
    loading,
    tokenLogin,
    tokenLogout,
    fetchData,
    login(userData) {
      setCookie("user", userData, { path: "/" });
      setUser(userData);
      if (userData.token) {
        tokenLogin(userData.token);
      }
    },
    logout() {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      setUser(null);
      tokenLogout();
    },
  };
};

export default useAuth;
