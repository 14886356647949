
// import { useContext } from "react";
import {Navigate, Outlet } from "react-router-dom";
import useToken from "../hooks/useToken";
import AppContext from "../AppContext";
import Loader from "./Loader";
import { useContext } from "react";
import { toast } from "react-toastify";

function ProtectedLayout(props) {
    const {token} = useToken();
    const {loading, user } = useContext(AppContext);
    

    if (!token) {
        // user is not authenticated
        return <Navigate to="/admin/login" />;
    }




    if (loading) {
      return <Loader/>
    } else {
      if (!user) {
        //user profile cannot be fetch - probabaly authentication error
        // return <h1>No user</h1>;
        return <Navigate to="/admin/login" />;
      }
    }

    //route rules for each user role
    const roles = props.roles || [];
    if (roles.length > 0 && !roles.includes(user.role)) {
        //user role is not allowed to access this route
        toast.error("You are not allowed to access that page");
        return <Navigate to="/admin" />;
    }
    

    return <Outlet />; //to display the inner children routes
}

export default ProtectedLayout;