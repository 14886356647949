import React, { useContext, useState } from "react";
import StatusLabel from "./StatusLabel";
import CircularLoader from "./CircularLoader";
import { toast } from "react-toastify";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "..";
import { confirm } from "react-confirm-box";
import HostRequestModal from "../pages/modal/HostRequestModal";
import { helpers } from "../helpers";
import { useTranslation } from "react-i18next";
import AppContext from "../AppContext";


const HostRequestsTable = ({ requests, onTableUpdated, loading = false }) => {

    const { t } = useTranslation();

    const {user} = useContext(AppContext);

    const [showModal, setShowModal] = useState(false);
    const [request, setHostRequest] = useState({});

    const onModalUpdated = (request) => {
        onTableUpdated(request);
    }

    const deleteHostRequest = async (request) => {
        if (!helpers.isSuperAdmin(user)) {
            toast.error("You are not authorized to perform this action");
            return;
        }

        //confirm delete
        const proceed = await confirm(t('are-you-sure-you-want-to-delete-this'));
        if (!proceed) return;

        const loading = toast.loading(t('please-wait'));

        const requestRef = doc(db, "host_requests", request.id);

        await deleteDoc(requestRef).then(() => {
            onTableUpdated(null);
            toast.success(t('deleted-successfully'));
        }).catch((error) => {
            toast.error(t('failed-to-delete'));
        });

        toast.dismiss(loading);
    }




    return (
        <>
      
            {/* <!-- New Table --> */}
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                <div className="w-full overflow-x-auto">
                    <table className="w-full whitespace-no-wrap">
                        <thead>
                            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                                <th className="px-4 py-3">{t('host-request')}</th>
                                <th className="px-4 py-3">{t('email-phone')}</th>
                                <th className="px-4 py-3">{t('status')}</th>
                                <th className="px-4 py-3">{t('date')}</th>
                                <th className="px-4 py-3">{t('action-0')}</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        {
                                loading && (
                                    <tr className="text-gray-700 dark:text-gray-400">
                                        <td className="px-4 py-3 text-sm" colSpan="5">
                                            <div className="flex justify-center items-center">
                                                <CircularLoader />
                                                <span>{t('loading')}</span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                (requests.length > 0) && requests.map((request) => (
                                    <tr className="text-gray-700 dark:text-gray-400" key={request.id}>
                                        <td className="px-4 py-3 text-sm">
                                            <div className="flex items-center text-sm">
                                                {/* <!-- Avatar with inset shadow --> */}
                                                <div className="relative hidden w-8 h-8 mr-3 rounded-full md:block">
                                                    <img className="object-cover w-full h-full rounded-full" src={helpers.getFirstImage(request.user.avatar, "avatar", request.user.full_name)} alt="" loading="lazy" />
                                                    <div className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></div>
                                                </div>
                                                <div>
                                                    <p className="font-semibold">#{request.doc_number} {request.user.full_name}</p>
                                                    <p className="text-xs text-gray-600 dark:text-gray-400">
                                                        {
                                                            request.user.city && request.user.city && (<>{request.user.city}, {request.user.region}</>)
                                                        }
                                                    </p>
                                                </div>
                                            </div>

                                        </td>
                                        <td className="px-4 py-3 text-sm">
                                            <p className="font-semibold">{request.user.email}</p>
                                            <p className="text-xs text-gray-600 dark:text-gray-400">
                                                {request.user.phone}
                                            </p>
                                        </td>
                                        <td className="px-4 py-3 text-xs">
                                            <StatusLabel status={request.status ?? "unknown"} />
                                        </td>
                                        <td className="px-4 py-3 text-sm">
                                            {request.created_at ? request.created_at.toDate().toLocaleDateString() : "Nil"}
                                        </td>
                                        <td className="px-4 py-3 text-sm text-right">
                                            <button onClick={() => { setHostRequest(request); setShowModal(true); }} id="row-edit" className="inline bg-blue-500 rounded text-white p-1 hover:bg-blue-600 disabled:bg-gray-800" data-modal-toggle="edit-modal">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 icon icon-tabler icon-tabler-pencil" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <desc>Download more icon variants from https://tabler-icons.io/i/pencil</desc>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                                                    <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
                                                </svg></button>
                                            <button onClick={() => deleteHostRequest(request)} className="inline text-center bg-red-700 rounded text-white p-1 hover:opacity-80 ml-2">
                                                <svg id="icon" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <desc>Download more icon variants from https://tabler-icons.io/i/trash</desc>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <line x1="4" y1="7" x2="20" y2="7"></line>
                                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                </svg>
                                                <svg id="loading" className="hidden animate-spin h-4 w-4 icon icon-tabler text-white" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            {
                                (requests.length === 0 && !loading) && (
                                    <tr className="text-gray-700 dark:text-gray-400">
                                        <td className="px-4 py-3 text-sm text-center" colSpan="5">
                                            <p id="row-content">{t('no-requests-found')}</p>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                    <span className="flex items-center col-span-3">
                        {t('showing')} {requests.length} of {requests.length}
                    </span>
                    <span className="col-span-2"></span>
                    {/* <!-- Pagination --> */}
                    {/* <span className="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
                        <nav aria-label="Table navigation">
                            <ul className="inline-flex items-center">
                                <li>
                                    <button className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple" aria-label="Previous">
                                        <svg aria-hidden="true" className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                                            <path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" fillRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        1
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        2
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 text-white transition-colors duration-150 bg-purple-600 border border-r-0 border-purple-600 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        3
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        4
                                    </button>
                                </li>
                                <li>
                                    <span className="px-3 py-1">...</span>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        8
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        9
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple" aria-label="Next">
                                        <svg className="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
                                            <path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" fillRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </span> */}
                </div>
            </div>

            <HostRequestModal request={request} setHostRequest={setHostRequest} showModal={showModal} setShowModal={setShowModal} onModalUpdated={onModalUpdated} />
        </>
    );
}


export default HostRequestsTable;