/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import SkyeModal from "../../components/SkyeModal";
import ReactStars from "react-rating-stars-component";
import { Lightbox } from "react-modal-image";
import { GeoPoint, Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db, storage } from "../../index";
import { toast } from "react-toastify";
import { helpers } from "../../helpers";
import AppContext from "../../AppContext";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import CircularLoader from "../../components/CircularLoader";
import Currency from "../../components/Currency";
import { useTranslation } from "react-i18next";


const PropertyModal = ({ showModal, setShowModal, property, setProperty, onModalUpdated }) => {

    const { t } = useTranslation();

    const { user } = useContext(AppContext);

    const [propertyData, setPropertyData] = useState(property);

    const [types, setTypes] = useState([]);
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [propertyImages, setPropertyImages] = useState([]);
    const [reviews, setReviews] = useState([]); //reviews

    const [typesLoading, setTypesLoading] = useState(true);
    const [regionsLoading, setRegionsLoading] = useState(true);
    const [citiesLoading, setCitiesLoading] = useState(true);
    const [municipalitiesLoading, setMunicipalitiesLoading] = useState(true);
    const [facilitiesLoading, setFacilitiesLoading] = useState(true);
    const [amenitiesLoading, setAmenitiesLoading] = useState(true);
    const [reviewsLoading, setReviewsLoading] = useState(false);



    const [selectedFacilities, setSelectedFacilities] = useState(property.facilities ?? []);
    const [selectedAmenities, setSelectedAmenities] = useState(property.facilities ?? []);

    const [showImage, setShowImage] = useState(false);
    const [image, setImage] = useState(null);

    useEffect(() => {
        setPropertyData(property);
        setSelectedFacilities(property.facilities ?? []);
        setSelectedAmenities(property.amenities ?? []);
        //load images to propertyImages
        if (property.images) {
            let images = [];
            property.images.forEach((image) => {
                images.push({
                    image: image,
                    file: null //not a file
                });
            }
            );
            setPropertyImages(images);
        } else {
            setPropertyImages([]);
        }
    }, [property]);


    const fetchTypes = async () => {
        setTypesLoading(true);
        const q = query(collection(db, "types"));
        const querySnapshot = await getDocs(q);
        let types = [];
        querySnapshot.forEach((doc) => {
            types.push({ id: doc.id, ...doc.data() });
        });
        setTypes(types);
        setTypesLoading(false);
    }

    const fetchRegions = async () => {
        setRegionsLoading(true);
        const q = query(collection(db, "regions"));
        const querySnapshot = await getDocs(q);
        let regions = [];
        querySnapshot.forEach((doc) => {
            regions.push({ id: doc.id, ...doc.data() });
        });
        //sort regions by name asc
        regions.sort((a, b) => {
            if (a.region_nom < b.region_nom) {
                return -1;
            }
            if (a.region_nom > b.region_nom) {
                return 1;
            }
            return 0;
        });
        setRegions(regions);
        setRegionsLoading(false);
    }

    const fetchCities = async () => {
        setCitiesLoading(true);
        const q = query(collection(db, "cities"));
        const querySnapshot = await getDocs(q);
        let cities = [];
        querySnapshot.forEach((doc) => {
            cities.push({ id: doc.id, ...doc.data() });
        });
        //sort cities by name asc
        cities.sort((a, b) => {
            if (a.prefecture_nom < b.prefecture_nom) {
                return -1;
            }
            if (a.prefecture_nom > b.prefecture_nom) {
                return 1;
            }
            return 0;
        });
        setCities(cities);
        setCitiesLoading(false);
    }

    const fetchMunicipalities = async () => {
        setMunicipalitiesLoading(true);
        const q = query(collection(db, "municipalities"));
        const querySnapshot = await getDocs(q);
        let municipalities = [];
        querySnapshot.forEach((doc) => {
            municipalities.push({ id: doc.id, ...doc.data() });
        });
        //sort municipalities by name asc
        municipalities.sort((a, b) => {
            if (a.commune_nom < b.commune_nom) {
                return -1;
            }
            if (a.commune_nom > b.commune_nom) {
                return 1;
            }
            return 0;
        });
        setMunicipalities(municipalities);
        setMunicipalitiesLoading(false);
    }

    const fetchFacilities = async () => {
        setFacilitiesLoading(true);
        const q = query(collection(db, "facilities"));
        const querySnapshot = await getDocs(q);
        let facilities = [];
        querySnapshot.forEach((doc) => {
            facilities.push({ id: doc.id, ...doc.data() });
        });
        setFacilities(facilities);
        setFacilitiesLoading(false);
    }

    const fetchAmenities = async () => {
        setAmenitiesLoading(true);
        const q = query(collection(db, "amenities"));
        const querySnapshot = await getDocs(q);
        let amenities = [];
        querySnapshot.forEach((doc) => {
            amenities.push({ id: doc.id, ...doc.data() });
        });
        setAmenities(amenities);
        setAmenitiesLoading(false);
    }

    const fetchReviews = async () => {
        setReviewsLoading(true);
        const q = query(collection(db, "reviews"), where("property_id", "==", property.id));
        const querySnapshot = await getDocs(q);
        let reviews = [];
        querySnapshot.forEach((doc) => {
            reviews.push({ id: doc.id, ...doc.data() });
        });
        setReviews(reviews);
        setReviewsLoading(false);
    }

    useEffect(() => {
        fetchTypes();
        fetchFacilities();
        fetchAmenities();
        fetchRegions();
        fetchCities();
        fetchMunicipalities();
        if (property.id) {
            fetchReviews();
        }
    }, []);


    const inputChange = (e) => {
        setPropertyData({ ...propertyData, [e.target.name]: e.target.value });
    }

    const handleFacilitiesChange = (value, checked) => {
        if (checked) {
            setSelectedFacilities([...selectedFacilities, value]);
        } else {
            setSelectedFacilities(selectedFacilities.filter(facility => facility.id !== value.id));
        }
    }

    const handleAmenitiesChange = (value, checked) => {
        if (checked) {
            setSelectedAmenities([...selectedAmenities, value]);
        } else {
            setSelectedAmenities(selectedAmenities.filter(amenity => amenity.id !== value.id));
        }
    }


    const imageChange = (e, index = null) => {
        let images = [...propertyImages];
        if (index !== null && e.target.files[0]) {
            images[index].file = e.target.files[0];
        } else if (e.target.files[0]) {
            images.push({
                image: URL.createObjectURL(e.target.files[0]),
                file: e.target.files[0],
            });
        }
        setPropertyImages(images);
    }

    const deleteImage = (index) => {
        let images = [...propertyImages];
        images.splice(index, 1);
        setPropertyImages(images);
    }

    const viewImage = (index) => {
        let images = [...propertyImages];
        setImage(images[index].image);
        setShowImage(true);
    }



    const submitForm = async (e) => {
        e.preventDefault();

        const loading = toast.loading(t('please-wait'));

        let formData = { ...propertyData }


        formData.facilities = selectedFacilities;
        formData.amenities = selectedAmenities;
        formData.type = types.find(type => type.id === formData.type_id);

        formData.facilities_ids = selectedFacilities.map(facility => facility.id);
        formData.amenities_ids = selectedAmenities.map(amenity => amenity.id);

        //add geo location point
        if (formData.latitude && formData.longitude) {
            var pos = new GeoPoint(formData.latitude, formData.longitude);
            formData.location = pos.toJSON();
        }

        formData.rating = formData.rating ?? 0;

        formData.region_slug = helpers.getSlug(formData.region ?? "");
        formData.city_slug = helpers.getSlug(formData.city ?? "");
        formData.municipality_slug = helpers.getSlug(formData.municipality ?? "");


        //upload images first and get both met urls and new urls together as array[]
        let images = [];
        for (let i = 0; i < propertyImages.length; i++) {
            const image = propertyImages[i];
            if (image.file && image.file !== null) {
                //upload image
                let file = image.file;
                const imageName = formData.name + "-" + helpers.generateRandomString(10) + "." + file.name.split(".").pop();
                const imageRef = ref(storage, "images/properties/" + imageName);
                await uploadBytes(imageRef, file).then(async (snapshot) => {
                    await getDownloadURL(imageRef).then((url) => {
                        images.push(url);
                    });
                }
                ).catch((error) => {
                    toast.error(t('failed-to-upload-an-image'));
                });
            } else {
                images.push(image.image);
            }
        }
        formData.images = images;

        var surface = (formData.surface_area ?? 0).toString();
        formData.surface_area = surface.length > 0 ? surface : 0;

        formData.furnished = formData.furnished ?? true;

        //price to number
        formData.price = parseFloat(formData.price ?? 0);
        //monthly_price to number
        formData.monthly_price = parseFloat(formData.monthly_price ?? 0);
        //daily_price to number
        formData.daily_price = parseFloat(formData.daily_price ?? 0);
        //bedrooms to number
        formData.bedrooms = parseFloat(formData.bedrooms ?? 0);
        //bathrooms to number
        formData.bathrooms = parseFloat(formData.bathrooms ?? 0);
        //beds to number
        formData.beds = parseFloat(formData.beds ?? 0);
        //latitude to number
        formData.latitude = parseFloat(formData.latitude ?? 0);
        //longitude to number
        formData.longitude = parseFloat(formData.longitude ?? 0);
        //surface_area to number
        formData.surface_area = parseFloat(formData.surface_area ?? 0);

        //if offer type is rent, either monthly_price or daily_price must be set
        if (formData.offer_type === "rent") {
            //if both monthly_price and daily_price are 0 or NaN
            if ((formData.monthly_price === 0 && formData.daily_price === 0) || (isNaN(formData.monthly_price) && isNaN(formData.daily_price))) {
                toast.dismiss(loading);
                toast.error(t('either-monthly-price-or-daily-price-must-be-set'));
                return;
            }
        } else if (formData.offer_type === "monthly-rent") {
            //if monthly_price is 0 or NaN
            if (formData.monthly_price === 0 || isNaN(formData.monthly_price)) {
                toast.dismiss(loading);
                toast.error(t('monthly-price-must-be-set'));
                return;
            }
        } else if (formData.offer_type === "daily-rent") {
            //if daily_price is 0 or NaN
            if (formData.daily_price === 0 || isNaN(formData.daily_price)) {
                toast.dismiss(loading);
                toast.error(t('daily-price-must-be-set'));
                return;
            }
        } else if (formData.offer_type === "sale") {
            //if price is 0 or NaN
            if (formData.price === 0 || isNaN(formData.price)) {
                toast.dismiss(loading);
                toast.error(t('price-must-be-set'));
                return;
            }
        }



        if (propertyData.id) { //update
            if (helpers.isHost(user)) {
                if (['publish', 'taken'].includes(propertyData.status)) {
                    toast.dismiss(loading);
                    toast.error(`You are not allowed to update a property with status "${propertyData.status}"`);
                    return;
                }
            }
            //remove id, created_at
            delete formData.id;
            delete formData.created_at;
            formData.updated_at = Timestamp.now();
            //generate search keywords from both name and address
            formData.search_keywords = helpers.generateSearchKeywords(formData.name + " " + formData.address);

            if (formData.user_id) {
                //get user info
                const userRef = doc(db, "users", formData.user_id);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    formData.user = { ...userSnap.data(), id: userSnap.id };
                }
            }

            if (formData.stuatus === "publish" && formData.post_type === "host" && formData.post_status === "pending" && formData.user.email) {
                formData.post_status = "publish";
                await helpers.sendMail(
                    formData.user.email,
                    `Your property "${formData.name}" has been published`,
                    `
                    <h3>${t('your-property-has-been-published')}</h3>
                    <p><b>${t('property-name-0')}:</b> ${formData.name}</p>
                    <p><b>Ref:</b> ${formData.doc_number}</p>
                    `
                )
            }

            //add to firebase
            const propertyRef = doc(db, "properties", propertyData.id);
            await updateDoc(propertyRef, formData, { merge: true }).then(async () => {

                //get the updated data
                const docSnap = await getDoc(propertyRef);
                if (docSnap.exists()) {
                    setProperty({ ...docSnap.data(), id: docSnap.id });
                    setPropertyData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success(t('updated-successfully'));
                }
            }).catch((error) => {
                toast.error(t('failed-to-update'));
            });

            helpers.log(user, "update", `${t('updated')} '<b>${formData.name}</b>' ${t('property')} ID: ${formData.doc_number}`);

        } else {
            //create
            formData.doc_number = await helpers.getNextDocumentNumber("properties");
            formData.user_id = user.id;
            formData.user = user;
            formData.created_at = Timestamp.now();
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.name + " " + formData.address);
            formData.slug = helpers.getSlug(formData.name);
            formData.status = formData.status ?? "publish";
            if (helpers.isHost(user)) {
                formData.status = "pending";
                formData.post_type = "host";
                formData.post_status = "pending";
            }

            const propertiesRef = collection(db, "properties");

            //add timestamp to slug if slug already exists
            const slugQuery = query(propertiesRef, where("slug", "==", formData.slug));
            const slugQuerySnapshot = await getDocs(slugQuery);
            if (slugQuerySnapshot.size > 0) {
                formData.slug += "-" + Date.now();
            }

            //add to firebase
            await addDoc(propertiesRef, formData).then(async (docRef) => {
                //get the updated data
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {


                    //push notification to topic "general"
                    if (formData.status === "publish") {
                        await helpers.pushNotificationToTopic("general",
                            {
                                title: t('new-property'),
                                body: formData.name,
                                image: formData.images[0]
                            },
                            {
                                "navigation": "new_properties",
                            }
                        );
                    }

                    if (helpers.isHost(user)) {
                        await helpers.sendMail(
                            "iamseunoyeniyi@gmail.com",
                            `${t('new-property')} - ${formData.name}`,
                            `
                            <h3>${t('new-property-listed')}</h3>
                            <p><b>${t('property-name-0')}:</b> ${formData.name}</p>
                            <p><b>${t('host')}:</b> ${user.full_name}</p>
                            `
                        );
                    }



                    setProperty({ id: docSnap.id, ...docSnap.data() });
                    setPropertyData({ id: docSnap.id, ...docSnap.data() });
                    onModalUpdated({ id: docSnap.id, ...docSnap.data() });
                    toast.success(t('property-created-successfully'));
                    if (helpers.isHost(user)) {
                        toast.info(t('your-property-will-be-published-after-admin-approval'));
                    }
                }
            }).catch((error) => {
                toast.error(t('something-went-wrong'));
            });

            helpers.log(user, "update", `${t('created')} '<b>${formData.name}</b>' ${t('property')} ID: ${formData.doc_number}`);

        }


        toast.dismiss(loading);
        setShowModal(false);
    }

    const header = (
        <div className="flex flex-row items-center justify-center">
            <div className="flex flex-row items-center justify-center gap-2">
                <div>{t('views')}:</div><div className="font-bold underline">{property.view_users?.length || 0}</div>
            </div>

        </div>
    );

    return (
        <>
            <SkyeModal header={header} title={propertyData.id ? t('property') : t('new-property')} size="max-w-6xl" flex={false} showModal={showModal} setShowModal={setShowModal}>
                <form autoComplete="off" autoSave="off" autoCapitalize="on" className="space-y-6" id="propertyData-edit-form" onSubmit={submitForm} method="POST" encType="multipart/form-data">
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <div className="grid grid-cols-6 gap-6">

                            <div className="col-span-6 grid grid-cols-6 gap-6">
                                {/* PROPERTY SIDE 1 */}
                                <div className="col-span-6 sm:col-span-3">

                                    <div className="">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            {t('property-name')}:
                                        </label>
                                        <input
                                            name='name'
                                            value={propertyData.name || ""}
                                            onChange={inputChange}
                                            required
                                            type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder={t('property-name')} />
                                    </div>

                                    <div className="my-4">
                                        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                                            {t('descripton')}:
                                        </label>
                                        <textarea
                                            name='description'
                                            value={propertyData.description || ""}
                                            onChange={inputChange}
                                            rows={7}
                                            required
                                            type="text" id="description" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder={t('about-property')}></textarea>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="offer_type" className="block text-sm font-medium text-gray-700">
                                            {t('offer-type-0')}:
                                        </label>
                                        <select
                                            name='offer_type'
                                            value={propertyData.offer_type || ""}
                                            onChange={inputChange}
                                            required
                                            type="text" id="status" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="">{t('select-offer')}</option>
                                            <option value="daily-rent">{t('daily-rent')}</option>
                                            <option value="monthly-rent">{t('monthly-rent')}</option>
                                            <option value="rent">{t('rent-monthly-daily')}</option>
                                            <option value="sale">{t('sale')}</option>
                                        </select>
                                    </div>
                                    {/* FOR SALE PRICE */}
                                    {
                                        propertyData.offer_type === "sale" &&
                                        (
                                            <div className="my-4">
                                                <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                                                    {t('sales-price')}:
                                                </label>
                                                <div className="mt-1 relative rounded-md shadow-sm">
                                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                        <span className="text-gray-500 sm:text-sm font-bold"><Currency /></span>
                                                    </div>
                                                    <input
                                                        name="price"
                                                        value={propertyData.price || ""}
                                                        onChange={inputChange}
                                                        required
                                                        type="number" id="price" className="bg-gray-50 border p-2.5 pl-11 font-bold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="0.00" />
                                                </div>
                                            </div>
                                        )
                                    }
                                    {/* FOR MONTHLY PRICE */}
                                    {
                                        (propertyData.offer_type === "rent" || propertyData.offer_type === "monthly-rent" || propertyData.offer_type === "daily-rent") &&
                                        (
                                            <div className={`my-4 ${propertyData.offer_type === "rent" ? "grid grid-cols-2 gap-3" : ""}`}>
                                                <div className={`${propertyData.offer_type === "daily-rent" ? "hidden" : ""}`}>
                                                    <label htmlFor="monthly_price" className="block text-sm font-medium text-gray-700">
                                                        {t('price-month')}:
                                                    </label>
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm font-bold"><Currency /></span>
                                                        </div>
                                                        <input
                                                            name="monthly_price"
                                                            value={propertyData.monthly_price || ""}
                                                            onChange={inputChange}
                                                            type="number" id="monthly_price" className="bg-gray-50 border p-2.5 pl-11 font-bold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="0.00" />
                                                    </div>
                                                </div>
                                                {/* FOR DAILY PRICE */}
                                                <div className={`${propertyData.offer_type === "monthly-rent" ? "hidden" : ""}`}>
                                                    <label htmlFor="daily_price" className="block text-sm font-medium text-gray-700">
                                                        {t('price-day')}:
                                                    </label>
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm font-bold"><Currency /></span>
                                                        </div>
                                                        <input
                                                            name="daily_price"
                                                            value={propertyData.daily_price || ""}
                                                            onChange={inputChange}
                                                            type="number" id="daily_price" className="bg-gray-50 border p-2.5 pl-11 font-bold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="0.00" />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className="my-3">
                                        <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                                            {t('property-type')}:
                                        </label>
                                        {
                                            typesLoading ?
                                                (
                                                    <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                        <CircularLoader className={"w-full text-center"} />
                                                    </div>
                                                )
                                                :
                                                (
                                                    <select
                                                        name='type_id'
                                                        value={propertyData.type_id || ""}
                                                        onChange={inputChange}
                                                        required
                                                        type="text" id="type_id" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option value="">{t('select-property-type')}</option>
                                                        {
                                                            types.map((propertyType, index) => (
                                                                <option key={index} value={propertyType.id}>{propertyType.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                )
                                        }

                                    </div>

                                    <div className="">
                                        <label className="block text-sm font-medium text-gray-700">
                                            {t('geo-location-latitude-longitude')}:
                                        </label>
                                        <div className="grid grid-cols-2 gap-3">
                                            <input
                                                name='latitude'
                                                value={propertyData.latitude || ""}
                                                onChange={inputChange}
                                                type="number" id="latitude" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="0.0" />
                                            <input
                                                name='longitude'
                                                value={propertyData.longitude || ""}
                                                onChange={inputChange}
                                                type="number" id="longitude" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="0.0" />
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <label className="block text-sm font-medium text-gray-700" htmlFor="surface_area">
                                            {t('surface-area')}
                                        </label>
                                        <input
                                            name='surface_area'
                                            value={propertyData.surface_area || ""}
                                            onChange={inputChange}
                                            type="number" id="surface_area" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder={t('surface-area-in-m')} />

                                    </div>

                                </div>


                                {/* PROPERTY SIDE 2 */}
                                <div className="col-span-6 sm:col-span-3">
                                    <div className="shadow px-5 py-4 pt-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            {t('facilities')}:
                                        </label>

                                        <div className="md:grid md:grid-flow-row-dense md:grid-cols-2 items-center mt-2">
                                            {
                                                facilitiesLoading && (<CircularLoader className={"w-full text-center col-span-2"} />)
                                            }
                                            {
                                                facilities.map((facility, index) => (
                                                    <div key={index} className="form-check form-check-inline inline-block">
                                                        <input
                                                            value={facility}
                                                            checked={selectedFacilities.some(selected => selected.id === facility.id)}
                                                            onChange={(event) => handleFacilitiesChange(facility, event.target.checked)}
                                                            id={`facility-${facility.id}`}
                                                            className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                                                        <label className="text-sm form-check-label inline text-gray-800 cursor-pointer" htmlFor={`facility-${facility.id}`}>{facility.name}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                    <div className="shadow px-5 py-4 pt-2 my-4">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            {t('amenities')}:
                                        </label>

                                        <div className="md:grid md:grid-flow-row-dense md:grid-cols-2 items-center mt-2">
                                            {
                                                amenitiesLoading && (<CircularLoader className={"w-full text-center col-span-2"} />)
                                            }
                                            {
                                                amenities.map((amenity, index) => (
                                                    <div key={index} className="form-check form-check-inline inline-block">
                                                        <input
                                                            value={amenity}
                                                            checked={selectedAmenities.some(selected => selected.id === amenity.id)}
                                                            onChange={(event) => handleAmenitiesChange(amenity, event.target.checked)}
                                                            id={`amenity-${amenity.id}`}
                                                            className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                                                        <label className="text-sm form-check-label inline text-gray-800 cursor-pointer" htmlFor={`amenity-${amenity.id}`}>{amenity.name}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                    <div className="col-span-6">
                                        <label className="text-lg font-bold">{t('furnished')}
                                            <input
                                                name='furnished'
                                                type="checkbox"
                                                checked={propertyData.furnished ?? true}
                                                onChange={(e) => setPropertyData({ ...propertyData, furnished: e.target.checked })}
                                                className="form-check-input h-4 w-4 border border-gray-300 rounded-sm ml-4 mt-1.5 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer" />
                                        </label>
                                    </div>

                                    {/* Rooms and Beds */}
                                    <div className="my-4 grid grid-cols-3 gap-3">
                                        <div className="">
                                            <label htmlFor="bedrooms" className="block text-sm font-medium text-gray-700">
                                                {t('bedrooms')}:
                                            </label>
                                            <input
                                                name='bedrooms'
                                                value={propertyData.bedrooms || ""}
                                                onChange={inputChange}
                                                // required
                                                type="number" id="bedrooms" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="0" />
                                        </div>

                                        <div className="">
                                            <label htmlFor="bathrooms" className="block text-sm font-medium text-gray-700">
                                                {t('bathrooms')}:
                                            </label>
                                            <input
                                                name='bathrooms'
                                                value={propertyData.bathrooms || ""}
                                                onChange={inputChange}
                                                // required
                                                type="number" id="bathrooms" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="0" />
                                        </div>

                                        <div className="">
                                            <label htmlFor="beds" className="block text-sm font-medium text-gray-700">
                                                {t('beds')}:
                                            </label>
                                            <input
                                                name='beds'
                                                value={propertyData.beds || ""}
                                                onChange={inputChange}
                                                type="number" id="beds" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="0" />
                                        </div>
                                    </div>
                                    {
                                        helpers.isAdministrator(user) && (
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                                                    {t('status')}:
                                                </label>
                                                <select
                                                    name='status'
                                                    value={propertyData.status || ""}
                                                    onChange={inputChange}
                                                    required
                                                    type="text" id="status" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                    <option value="publish">{t('publish')}</option>
                                                    <option value="pending">{t('pending')}</option>
                                                    <option value="draft">{t('draft')}</option>
                                                </select>
                                            </div>
                                        )
                                    }

                                </div>

                                {/* divider */}
                                <div className="col-span-6">
                                    <hr className="my-1" />
                                </div>

                                <h1 className="col-span-6 text-lg">{t('property-images')}</h1>
                                {/* horizontal rows */}
                                <div className="col-span-6 flex overflow-x-scroll space-x-5">
                                    {/* images */}
                                    {
                                        propertyImages.map((propertyImage, index) => {
                                            let image = propertyImage.image;
                                            if (propertyImage.file) {
                                                image = URL.createObjectURL(propertyImage.file);
                                            }
                                            return (
                                                <div key={index} className="flex-shrink-0 w-[200px] h-[200px] relative">
                                                    <img src={image} alt="" className="w-full h-full object-cover rounded-md cursor-pointer" onClick={() => viewImage(index)} />
                                                    {/* delete button at right corner */}
                                                    <button onClick={() => deleteImage(index)} type="button" className="absolute top-1 right-1 w-8 h-8 bg-red-500 rounded-full flex items-center justify-center text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                                        <span className="sr-only">Delete</span>
                                                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fillRule="evenodd" d="M5.293 5.293a1 1 0 011.414 0L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 01-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                        </svg>
                                                    </button>

                                                </div>
                                            );
                                        })
                                    }
                                    {/* picker */}
                                    <div className="flex-shrink-0 w-[200px] h-[200px]">
                                        <label
                                            className="flex justify-center w-full h-full px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                                            <span className="flex items-center space-x-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon w-12 h-12 icon-tabler icon-tabler-camera" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#597e8d" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                                                    <path d="M9 13a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                                </svg>
                                            </span>
                                            {/* ony image files */}
                                            <input type="file" name="image_file" className="hidden" accept="image/*" onChange={(e) => imageChange(e)} />
                                        </label>
                                    </div>
                                </div>

                            </div>

                            {/* divider */}
                            <div className="col-span-6">
                                <hr className="my-5" />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="owners_name" className="block text-sm font-medium text-gray-700">
                                    {t('owners-full-name')}:
                                </label>
                                <input
                                    name='owners_name'
                                    value={propertyData.owners_name || ""}
                                    onChange={inputChange}
                                    type="text" id="owners_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="owners_phone" className="block text-sm font-medium text-gray-700">
                                    {t('owners-phone')}:
                                </label>
                                <input
                                    name='owners_phone'
                                    value={propertyData.owners_phone || ""}
                                    onChange={inputChange}
                                    type="text" id="owners_phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="owners_email" className="block text-sm font-medium text-gray-700">
                                    {t('owners-email')}:
                                </label>
                                <input
                                    name='owners_email'
                                    value={propertyData.owners_email || ""}
                                    onChange={inputChange}
                                    type="text" id="owners_email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                    {t('property-address')}:
                                </label>
                                <input
                                    name='address'
                                    value={propertyData.address || ""}
                                    onChange={inputChange}
                                    required
                                    type="text" id="address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                            </div>

                            {/* regions */}
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                    {t('region')}
                                </label>
                                {
                                    regionsLoading ?
                                        (
                                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <CircularLoader className={"w-full text-center"} />
                                            </div>
                                        )
                                        :
                                        (
                                            <select
                                                name='region'
                                                value={propertyData.region || ""}
                                                onChange={inputChange}
                                                required
                                                type="text" id="region" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            >
                                                <option value="">{t('select-region')}</option>
                                                {
                                                    (() => {
                                                        return regions.map((region, index) => (
                                                            <option key={index} value={region.region_nom}>{region.region_nom}</option>
                                                        ));
                                                    })()
                                                }
                                            </select>
                                        )
                                }
                            </div>

                            {/* cities */}
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                    {t('city')}
                                </label>
                                {
                                    citiesLoading ?
                                        (
                                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <CircularLoader className={"w-full text-center"} />
                                            </div>
                                        )
                                        :
                                        (
                                            <select
                                                name='city'
                                                value={propertyData.city || ""}
                                                onChange={inputChange}
                                                required
                                                type="text" id="city" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            >
                                                <option value="">{t('select-city')}</option>
                                                {
                                                    (() => {
                                                        if (!propertyData.region || propertyData.region === "") {
                                                            return [];
                                                        }
                                                        let region = regions.find(region => region.region_nom === propertyData.region);
                                                        if (!region) {
                                                            return [];
                                                        }
                                                        //cities where region id is equal to the selected region id
                                                        return cities.filter(city => city.region_id === region.id).map((city, index) => (
                                                            <option key={index} value={city.prefecture_nom}>{city.prefecture_nom}</option>
                                                        ));

                                                    })()
                                                }
                                            </select>
                                        )
                                }
                            </div>

                            {/* municipalities */}
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="municipality" className="block text-sm font-medium text-gray-700">
                                    {t('municipality')}
                                </label>
                                {
                                    municipalitiesLoading ?
                                        (
                                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <CircularLoader className={"w-full text-center"} />
                                            </div>
                                        )
                                        :
                                        (
                                            <select
                                                name='municipality'
                                                value={propertyData.municipality || ""}
                                                onChange={inputChange}
                                                required
                                                type="text" id="municipality" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            >
                                                <option value="">{t('select-municipality')}</option>
                                                {
                                                    (() => {
                                                        if (!propertyData.city || propertyData.city === "") {
                                                            return [];
                                                        }
                                                        let city = cities.find(city => city.prefecture_nom === propertyData.city);
                                                        if (!city) {
                                                            return [];
                                                        }
                                                        //municipalities where city id is equal to the selected city id
                                                        return municipalities.filter(municipality => municipality.prefecture_id === city.id).map((municipality, index) => (
                                                            <option key={index} value={municipality.commune_nom}>{municipality.commune_nom}</option>
                                                        ));
                                                    })()
                                                }
                                            </select>
                                        )
                                }
                            </div>


                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="zip_code" className="block text-sm font-medium text-gray-700">
                                    {t('zip-code')}:
                                </label>
                                <input
                                    name='zip_code'
                                    value={propertyData.zip_code || ""}
                                    onChange={inputChange}
                                    type="text" id="zip_code" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                            </div>






                            {/* REVIEWS */}
                            {
                                (reviewsLoading && property.id) && (<CircularLoader className={"w-full text-center col-span-6"} />)
                            }
                            {
                                (reviews.length > 0) ?
                                    (
                                        <>
                                            {/* divider */}
                                            <div className="col-span-6">
                                                <hr className="my-5" />
                                            </div>


                                            <h1 className="col-span-6 text-xl">{t('reviews')} </h1>
                                            {/* horizontal rows */}
                                            <div className="col-span-6 flex overflow-x-auto space-x-5 pb-3">
                                                {
                                                    reviews.map((propertyReview, index) => (
                                                        <div key={propertyReview.id} className="flex-shrink-0 w-[300px] max-h-[120px] h-max">
                                                            <div className="flex-col justify-start items-start shadow p-2 rounded-md w-[300px] max-h-[120px] h-max overflow-ellipsis overflow-y-hidden mb-2">
                                                                <div className="bg-white justify-start items-center gap-2.5 inline-flex w-full">
                                                                    <div className="w-[42px] h-[42px] relative">
                                                                        <img className="w-[42px] h-[42px] left-0 top-0 absolute rounded-full" src={helpers.getFirstImage(propertyReview.user.avatar, "avatar", propertyReview.user.full_name)} alt="" />
                                                                    </div>
                                                                    <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                                                                        <div className="text-gray-900 text-[16px] font-normal leading-none tracking-wide">{propertyReview.user.full_name}</div>
                                                                        <div className="justify-start items-start gap-2 inline-flex">
                                                                            <ReactStars
                                                                                count={5}
                                                                                value={propertyReview.rating}
                                                                                size={16}
                                                                                activeColor="#ffd700"
                                                                                edit={false}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <span className="text-zinc-500 text-xs font-normal leading-snug tracking-wide overflow-ellipsis overflow-y-ellipsis">
                                                                        {propertyReview.review}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    ) : null
                            }

                        </div>

                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-4 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <span id="submit-text">{propertyData.id ? "UPDATE" : "SUBMIT"}</span>
                        </button>
                    </div>
                </form>
            </SkyeModal >

            {/* image modal */}
            {
                showImage ? (
                    <Lightbox
                        medium={image}
                        small={image}
                        large={image}
                        alt={propertyData.name}
                        onClose={() => setShowImage(false)}
                    />
                ) : null
            }

        </>
    );
}

export default PropertyModal;