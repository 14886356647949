import * as FileSaver from "file-saver";
import { useContext } from "react";
import XLSX from "sheetjs-style";
import AppContext from "./AppContext";
import { helpers } from "./helpers";

const ExportExcelButton = ({
  children,
  excelData,
  fileName,
  className = null,
}) => {


    const {user} = useContext(AppContext);

    if (!helpers.isSuperAdmin(user)) {
        return null;
    }


  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button onClick={exportToExcel} className={className}>
      {children}
    </button>
  );
};

export default ExportExcelButton;
