import React, { useContext, useState } from "react";
import TypeModal from "../pages/modal/TypeModal";
import CircularLoader from "./CircularLoader";
import { toast } from "react-toastify";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "..";
import { confirm } from "react-confirm-box";
import { useTranslation } from "react-i18next";
import { helpers } from "../helpers";
import AppContext from "../AppContext";


const TypesTable = ({ types, onTableUpdated, loading = false }) => {

    const {t} = useTranslation();

    const {user} = useContext(AppContext);

    const [showModal, setShowModal] = useState(false);

    const [type, setType] = useState({});

    const onModalUpdated = (type) => {
        onTableUpdated(type);
    }

    const deleteType = async (type) => {
        if (!helpers.isSuperAdmin(user)) {
            toast.error("You are not authorized to perform this action");
            return;
        }

        //confirm delete
        const proceed = await confirm(`${t('are-you-sure-you-want-to-delete')} ${type.name}?`);
        if (!proceed) return;

        const loading = toast.loading(t('please-wait'));

        const typeRef = doc(db, "types", type.id);

        await deleteDoc(typeRef).then(() => {
            onTableUpdated(null);
            toast.success(t('deleted-successfully'));
        }).catch((error) => {
            toast.error(t('failed-to-delete'));
        });
        toast.dismiss(loading);
    }

    return (
        <>
            <div className="text-right mb-5">
                <button onClick={() => { setType({}); setShowModal(true); }} className="text-white bg-blue-500 py-1 px-5 rounded hover:bg-blue-600" type="button" data-modal-toggle="add-modal">
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <desc>Download more icon variants from https://tabler-icons.io/i/plus</desc>
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg> {t('new')}</button>
            </div>

            {/* <!-- New Table --> */}
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                <div className="w-full overflow-x-auto">
                    <table className="w-full whitespace-no-wrap" id="types-table">
                        <thead>
                            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                                <th className="px-4 py-3">{t('name')}</th>
                                <th className="px-4 py-3 text-center">{t('slug')}</th>
                                <th className="px-4 py-3 text-right">{t('action')}</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        {
                                loading && (
                                    <tr className="text-gray-700 dark:text-gray-400">
                                        <td className="px-4 py-3 text-sm" colSpan="3">
                                            <div className="flex justify-center items-center">
                                                <CircularLoader />
                                                <span>{t('loading')}</span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }

                            {
                                (types.length > 0) && types.map((type) => (
                                    <tr key={type.id} className="text-gray-700 dark:text-gray-400" id="aW8VVjxUdUTnbSgTZ7g1">
                                        <td className="px-4 py-3">
                                            <div className="flex items-center text-sm">

                                                <div>
                                                    <p id="row-title" className="font-semibold">{type.name}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-4 py-3 text-sm text-center">
                                            <p id="row-content">{type.slug}</p>
                                        </td>
                                        <td className="px-4 py-3 text-sm text-right">
                                            <button onClick={() => { setType(type); setShowModal(true); }} id="row-edit" className="inline bg-blue-500 rounded text-white p-1 hover:bg-blue-600 disabled:bg-gray-800" data-modal-toggle="edit-modal">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 icon icon-tabler icon-tabler-pencil" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <desc>Download more icon variants from https://tabler-icons.io/i/pencil</desc>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                                                    <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
                                                </svg></button>
                                            <button onClick={() => deleteType(type)} className="inline text-center bg-red-700 rounded text-white p-1 hover:opacity-80 ml-2">
                                                <svg id="icon" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <desc>Download more icon variants from https://tabler-icons.io/i/trash</desc>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <line x1="4" y1="7" x2="20" y2="7"></line>
                                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                </svg>
                                                <svg id="loading" className="hidden animate-spin h-4 w-4 icon icon-tabler text-white" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            {
                                (types.length === 0 && !loading) && (
                                    <tr className="text-gray-700 dark:text-gray-400">
                                        <td className="px-4 py-3 text-sm text-center" colSpan="3">
                                            <p id="row-content">{t('no-types-found')}</p>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody >
                    </table>
                </div>
            </div>


            {/* MODAL */}
            <TypeModal showModal={showModal} setShowModal={setShowModal} type={type} setType={setType} onModalUpdated={onModalUpdated} />


        </>
    );
}


export default TypesTable;