/* eslint-disable react-hooks/exhaustive-deps */
'use client';
import useDocumentTitle from "../hooks/userDocumentTitle";
import logo from "../assets/images/logo.png"
import { useTranslation } from "react-i18next";


const EmailVerification = () => {
    const {t} = useTranslation();
    useDocumentTitle(t('email-verification'));

    return (
        <>
            <div className="h-screen w-full bg-[#f7fafc] px-5">
                <div className="flex flex-col items-center justify-center min-h-screen">
                    <div className="w-full sm:w-2/3 md:w-1/2 lg:w-1/3">
                        <img src={logo} alt="Logo" className="mx-auto mb-6 w-24 sm:w-32"/>
                            <div className="bg-white shadow-md p-6 rounded-lg">
                                <h1 className="text-2xl sm:text-3xl font-bold text-center mb-4">{t('email-verified')}</h1>
                                <p className="text-center text-gray-600">{t('thank-you-for-verifying-your-email-address')}</p>
                                <p className="text-center text-gray-600">{t('you-can-now-contnue-using-our-app')}</p>
                            </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailVerification;