import React, { useContext, useState } from "react";
import { helpers } from "../helpers";
import Currency from "./Currency";
import StatusLabel from "./StatusLabel";
import CircularLoader from "./CircularLoader";
import { toast } from "react-toastify";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "..";
import { confirm } from "react-confirm-box";
import BookingModal from "../pages/modal/BookingModal";
import { useTranslation } from "react-i18next";
import AppContext from "../AppContext";
import ExportExcelButton from "../excelexport";


const BookingsTable = ({bookings, onTableUpdated, loading = false}) => {

    const {t} = useTranslation();

    const {user} = useContext(AppContext);

    const [showModal, setShowModal] = useState(false);
    const [booking, setBooking] = useState({});

    const onModalUpdated = (booking) => {
        onTableUpdated(booking);
    }


    const deleteBooking = async (booking) => {
        if (!helpers.isSuperAdmin(user)) {
            toast.error("You are not authorized to perform this action");
            return;
        }

        //confirm delete
        const proceed = await confirm(`${t('are-you-sure-you-want-to-delete')} ${booking.name}?`);
        if (!proceed) return;

        const loading = toast.loading(t('please-wait'));

        const bookingRef = doc(db, "bookings", booking.id);

        await deleteDoc(bookingRef).then(() => {
            onTableUpdated(null);
            toast.success(t('deleted-successfully'));
        }
        ).catch((error) => {
            toast.error(t('failed-to-delete'));
        }
        );

        toast.dismiss(loading);

    }



    return (
        <>

<div className="flex flex-row items-center justify-end mb-5 mt-5">
                <ExportExcelButton
                    excelData={
                        bookings.map((booking) => {
                            return {
                                "Booking #": booking.doc_number,
                                "Property": booking.property.name,
                                "User": booking.user.full_name,
                                "Amount": booking.total_price,
                                "Status": booking.status,
                                "Date": booking.created_at.toDate().toLocaleDateString()
                            }
                        })
                    }
                    fileName={`${t('bookings')}-${new Date().toLocaleDateString()}`}
                    className="text-white mr-2 bg-green-500 py-1 px-5 rounded hover:bg-blue-600 flex flex-row items-center justify-center" type="button" data-modal-toggle="add-modal">
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>

                    {t('export')}
                </ExportExcelButton>
            </div>


            {/* <!-- New Table --> */}
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                <div className="w-full overflow-x-auto">
                    <table className="w-full whitespace-no-wrap">
                        <thead>
                            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                                <th className="px-4 py-3">{t('booking')}</th>
                                <th className="px-4 py-3">{t('property')}</th>
                                <th className="px-4 py-3">{t('amount')}</th>
                                <th className="px-4 py-3">{t('status')}</th>
                                <th className="px-4 py-3">{t('date')}</th>
                                <th className="px-4 py-3">{t('action-0')}</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        {
                                loading && (
                                    <tr className="text-gray-700 dark:text-gray-400">
                                        <td className="px-4 py-3 text-sm" colSpan="6">
                                            <div className="flex justify-center items-center">
                                                <CircularLoader />
                                                <span>{t('loading')}</span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                (bookings.length > 0) && bookings.map((booking) => (
                                    <tr className="text-gray-700 dark:text-gray-400" key={booking.id}>
                                        <td className="px-4 py-3">
                                            <div className="flex items-center text-sm">
                                                {/* <!-- Avatar with inset shadow --> */}
                                                <div className="relative hidden w-8 h-8 mr-3 rounded-full md:block">
                                                    <img className="object-cover w-full h-full rounded-full" src={helpers.getFirstImage(booking.user.avatar, "avatar", booking.user.full_name)} alt="" loading="lazy" />
                                                    <div className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></div>
                                                </div>
                                                <div
                                                    onClick={() => { setBooking(booking); setShowModal(true); }}
                                                    className="cursor-pointer hover:text-[#6ae2do] hover:underline">
                                                    <p className="font-semibold">#{booking.doc_number} {booking.user.full_name}</p>
                                                    <p className="text-xs text-gray-600 dark:text-gray-400">
                                                        {
                                                            (() => {
                                                                if (!booking.user.city) return null;
                                                                if (!booking.user.region) return null;
                                                                if (booking.user.city.length === 0 || booking.user.region.length === 0) return null;
                                                                return (booking.user.city, booking.user.region);

                                                            })()
                                                        }
                                                        
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            onClick={() => { setBooking(booking); setShowModal(true); }}
                                             className="px-4 py-3 text-sm cursor-pointer hover:text-[#6ae2do] hover:underline">
                                            <b>{helpers.capitalize(booking.property.name)}</b> <br />
                                            {booking.property.address}
                                        </td>
                                        <td className="px-4 py-3 text-sm">
                                            <Currency />{helpers.money(booking.total_price)}
                                        </td>
                                        <td className="px-4 py-3 text-xs">
                                            <StatusLabel status={booking.status} />
                                        </td>
                                        <td className="px-4 py-3 text-sm">
                                            {booking.created_at.toDate().toLocaleDateString()}
                                        </td>
                                        <td className="px-4 py-3 text-sm text-right">
                                            <button onClick={() => { setBooking(booking); setShowModal(true); }} id="row-edit" className="inline bg-blue-500 rounded text-white p-1 hover:bg-blue-600 disabled:bg-gray-800" data-modal-toggle="edit-modal">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 icon icon-tabler icon-tabler-pencil" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <desc>Download more icon variants from https://tabler-icons.io/i/pencil</desc>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                                                    <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
                                                </svg></button>
                                            <button onClick={() => { deleteBooking(booking) }} className="inline text-center bg-red-700 rounded text-white p-1 hover:opacity-80 ml-2">
                                                <svg id="icon" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <desc>Download more icon variants from https://tabler-icons.io/i/trash</desc>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <line x1="4" y1="7" x2="20" y2="7"></line>
                                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                </svg>
                                                <svg id="loading" className="hidden animate-spin h-4 w-4 icon icon-tabler text-white" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))

                            }
                            {
                                (bookings.length === 0 && !loading) && (
                                    <tr className="text-gray-700 dark:text-gray-400">
                                        <td className="px-4 py-3 text-sm text-center" colSpan={6}>
                                            {t('no-bookings-yet')}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                    <span className="flex items-center col-span-3">
                        {t('showing')} {bookings.length} of {bookings.length}
                    </span>
                    <span className="col-span-2"></span>
                    {/* <!-- Pagination --> */}
                    {/* <span className="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
                        <nav aria-label="Table navigation">
                            <ul className="inline-flex items-center">
                                <li>
                                    <button className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple" aria-label="Previous">
                                        <svg aria-hidden="true" className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                                            <path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" fillRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        1
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        2
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 text-white transition-colors duration-150 bg-purple-600 border border-r-0 border-purple-600 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        3
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        4
                                    </button>
                                </li>
                                <li>
                                    <span className="px-3 py-1">...</span>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        8
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        9
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple" aria-label="Next">
                                        <svg className="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
                                            <path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" fillRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </span> */}
                </div>
            </div>

            <BookingModal showModal={showModal} setShowModal={setShowModal} booking={booking} setBooking={setBooking} onModalUpdated={onModalUpdated} />
        </>
    );
}


export default BookingsTable;