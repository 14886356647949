import { useContext, useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc } from "firebase/firestore";
import { db } from "../../index";
import { toast } from "react-toastify";
import { helpers } from "../../helpers";
import CircularLoader from "../../components/CircularLoader";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";

const UserModal = ({ showModal, setShowModal, user, setUser, onModalUpdated }) => {

    const {t} = useTranslation();

    const [userData, setUserData] = useState(user ?? {
        status: "active",
    });

    const context = useContext(AppContext);

    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);

    const [regionsLoading, setRegionsLoading] = useState(true);
    const [citiesLoading, setCitiesLoading] = useState(true);
    const [municipalitiesLoading, setMunicipalitiesLoading] = useState(true);

    useEffect(() => {
        setUserData(user);
    }, [user]);

    useEffect(() => {
        fetchRegions();
        fetchCities();
        fetchMunicipalities();
    }, []);

    const fetchRegions = async () => {
        setRegionsLoading(true);
        const q = query(collection(db, "regions"));
        const querySnapshot = await getDocs(q);
        let regions = [];
        querySnapshot.forEach((doc) => {
            regions.push({ id: doc.id, ...doc.data() });
        });
        //sort regions by name asc
        regions.sort((a, b) => {
            if (a.region_nom < b.region_nom) {
                return -1;
            }
            if (a.region_nom > b.region_nom) {
                return 1;
            }
            return 0;
        });

        setRegions(regions);
        setRegionsLoading(false);
    }

    const fetchCities = async () => {
        setCitiesLoading(true);
        const q = query(collection(db, "cities"));
        const querySnapshot = await getDocs(q);
        let cities = [];
        querySnapshot.forEach((doc) => {
            cities.push({ id: doc.id, ...doc.data() });
        });
        //sort cities by name asc
        cities.sort((a, b) => {
            if (a.prefecture_nom < b.prefecture_nom) {
                return -1;
            }
            if (a.prefecture_nom > b.prefecture_nom) {
                return 1;
            }
            return 0;
        });


        setCities(cities);
        setCitiesLoading(false);
    }

    const fetchMunicipalities = async () => {
        setMunicipalitiesLoading(true);
        const q = query(collection(db, "municipalities"));
        const querySnapshot = await getDocs(q);
        let municipalities = [];
        querySnapshot.forEach((doc) => {
            municipalities.push({ id: doc.id, ...doc.data() });
        });
        //sort municipalities by name asc
        municipalities.sort((a, b) => {
            if (a.commune_nom < b.commune_nom) {
                return -1;
            }
            if (a.commune_nom > b.commune_nom) {
                return 1;
            }
            return 0;
        });

        setMunicipalities(municipalities);
        setMunicipalitiesLoading(false);
    }

    const onInputChanged = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    }

    const generatePassword = () => {
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const passwordLength = 8;
        var password = "";

        for (var i = 0; i <= passwordLength; i++) {
            const randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }

        setUserData({ ...userData, password: password });
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();

        const loading = toast.loading(t('please-wait'));

        let formData = { ...userData };


        formData.region_slug = helpers.getSlug(formData.region ?? "");
        formData.city_slug = helpers.getSlug(formData.city ?? "");
        formData.municipality_slug = helpers.getSlug(formData.municipality ?? "");


        if (formData.id) { //update
            //remove id, created_at
            delete formData.id;
            delete formData.created_at;
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.full_name);

            //add to firebase
            const userRef = doc(db, "users", userData.id);
            await updateDoc(userRef, formData, { merge: true }).then(async () => {
                //get the updated data
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    setUser({ ...docSnap.data(), id: docSnap.id });
                    setUserData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success(t('updated-successfully'));
                } else {
                    toast.error(t('failed-to-update'));
                }
            }).catch((error) => {
                toast.error(t('failed-to-update'));
            });

            helpers.log(context.user, "update", `user <b>(${formData.full_name})</b> updated`);

        } else { //add
            formData.doc_number = await helpers.getNextDocumentNumber("users");
            formData.created_at = Timestamp.now();
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.full_name);

            const usersRef = collection(db, "users");
            await addDoc(usersRef, formData).then(async (docRef) => {
                //get the added data
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setUser({ ...docSnap.data(), id: docSnap.id });
                    setUserData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success(t('added-successfully'));
                } else {
                    toast.error(t('failed-to-add'));
                }
            }).catch((error) => {
                toast.error(t('failed-to-add'));
            });

            helpers.log(context.user, "add", `user <b>(${formData.full_name})</b> added`);
        }

        toast.dismiss(loading);
        setShowModal(false);
        onModalUpdated(formData);
    }



    return (
        <>
            <SkyeModal title={userData.id ? t('user-information') : t('new-user')} size="max-w-7xl" flex={false} showModal={showModal} setShowModal={setShowModal}>
                <form className="space-y-6" id="user-edit-form" onSubmit={onFormSubmit} method="POST" encType="multipart/form-data">
                    {/*body*/}
                    <div className="relative p-6 flex-auto">

                        {/* body */}

                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="full_name" className="block text-sm font-medium text-gray-700">
                                    {t('full-name')}
                                </label>
                                <input
                                    name='full_name'
                                    value={userData.full_name || ""}
                                    onChange={onInputChanged}
                                    type="text" id="full_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('full-name')} />
                            </div>
                            {
                                (userData.email && userData.id) ?
                                    (
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                {t('email-address')}
                                            </label>
                                            <input
                                                name='email'
                                                defaultValue={userData.email || ""}
                                                readOnly
                                                disabled
                                                type="email" className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('email-address')} />

                                        </div>
                                    ) :
                                    (
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                {t('email-address')}
                                            </label>
                                            <input
                                                name='email'
                                                value={userData.email || ""}
                                                onChange={onInputChanged}
                                                type="email" className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('email-address')} />

                                        </div>
                                    )
                            }
                            {/* regions */}
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                    {t('region')}
                                </label>
                                {
                                    regionsLoading ?
                                        (
                                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <CircularLoader className={"w-full text-center"} />
                                            </div>
                                        )
                                        :
                                        (
                                            <select
                                                name='region'
                                                value={userData.region || ""}
                                                onChange={onInputChanged}
                                                type="text" id="region" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            >
                                                <option value="">{t('select-region')}</option>
                                                {
                                                    (() => {
                                                        return regions.map((region, index) => (
                                                            <option key={index} value={region.region_nom}>{region.region_nom}</option>
                                                        ));
                                                    })()
                                                }
                                            </select>
                                        )
                                }
                            </div>

                            {/* cities */}
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                    {t('city')}
                                </label>
                                {
                                    citiesLoading ?
                                        (
                                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <CircularLoader className={"w-full text-center"} />
                                            </div>
                                        )
                                        :
                                        (
                                            <select
                                                name='city'
                                                value={userData.city || ""}
                                                onChange={onInputChanged}
                                                type="text" id="city" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            >
                                                <option value="">{t('select-city')}</option>
                                                {
                                                    (() => {
                                                        if (!userData.region || userData.region === "") {
                                                            return [];
                                                        }
                                                        let region = regions.find(region => region.region_nom === userData.region);
                                                        if (!region) {
                                                            return [];
                                                        }
                                                        //cities where region id is equal to the selected region id
                                                        return cities.filter(city => city.region_id === region.id).map((city, index) => (
                                                            <option key={index} value={city.prefecture_nom}>{city.prefecture_nom}</option>
                                                        ));

                                                    })()
                                                }
                                            </select>
                                        )
                                }
                            </div>

                            {/* municipalities */}
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="municipality" className="block text-sm font-medium text-gray-700">
                                    {t('municipality')}
                                </label>
                                {
                                    municipalitiesLoading ?
                                        (
                                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <CircularLoader className={"w-full text-center"} />
                                            </div>
                                        )
                                        :
                                        (
                                            <select
                                                name='municipality'
                                                value={userData.municipality || ""}
                                                onChange={onInputChanged}
                                                type="text" id="municipality" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            >
                                                <option value="">{t('select-municipality')}</option>
                                                {
                                                    (() => {
                                                        if (!userData.city || userData.city === "") {
                                                            return [];
                                                        }
                                                        let city = cities.find(city => city.prefecture_nom === userData.city);
                                                        if (!city) {
                                                            return [];
                                                        }
                                                        //municipalities where city id is equal to the selected city id
                                                        return municipalities.filter(municipality => municipality.prefecture_id === city.id).map((municipality, index) => (
                                                            <option key={index} value={municipality.commune_nom}>{municipality.commune_nom}</option>
                                                        ));
                                                    })()
                                                }
                                            </select>
                                        )
                                }
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                    {t('address')}
                                </label>
                                <input
                                    name='address'
                                    value={userData.address || ""}
                                    onChange={onInputChanged}
                                    type="text" id="address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('address')} />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                    {t('phone')}
                                </label>
                                <input
                                    name='phone'
                                    value={userData.phone || ""}
                                    onChange={onInputChanged}
                                    required
                                    type="text" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('phone')} />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="zip_code" className="block text-sm font-medium text-gray-700">
                                    {t('zip')}
                                </label>
                                <input
                                    name='zip_code'
                                    value={userData.zip_code || ""}
                                    onChange={onInputChanged}
                                    type="text" id="zip_code" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('zip-code')} />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                                    {t('gender')}
                                </label>
                                <select
                                    name="gender"
                                    value={userData.gender || ""}
                                    onChange={onInputChanged}
                                    id="gender" className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="">{t('none')}</option>
                                    <option value="Male">{t('male')}</option>
                                    <option value="Female">{t('female')}</option>
                                </select>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
                                    {t('date-of-birth')}
                                </label>
                                <input
                                    name='dob'
                                    value={userData.dob || ""}
                                    onChange={onInputChanged}
                                    type="date" id="dob" className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>

                            {
                                (!userData.id && (
                                    <div className="col-span-6 grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                {t('password')}
                                            </label>
                                            <div className="grid grid-cols-4 gap-x-2">
                                                <input
                                                    name='password'
                                                    value={userData.password || ""}
                                                    onChange={onInputChanged}
                                                    required
                                                    type="text" id="password" className="col-span-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('password')} />
                                                <div className="flex items-center">
                                                    <button type="button" onClick={generatePassword} className="py-1 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200">{t('generate')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                                    {t('role')}
                                </label>
                                <select
                                    name='role'
                                    value={userData.role || ""}
                                    onChange={onInputChanged}
                                    required
                                    id="role" className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="">{t('select-a-role')}</option>
                                    <option value="user">{t('user')}</option>
                                    <option value="host">{t('host')}</option>
                                    {
                                        helpers.isSuperAdmin(context.user) ?
                                            (
                                                <>
                                                    <option value="admin">{t('admin')}</option>
                                                    <option value="super-admin">{t('super-admin')}</option>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <option value="admin" disabled>{t('admin')}</option>
                                                    <option value="super-admin" disabled>{t('super-admin')}</option>
                                                </>
                                            )
                                    }
                                </select>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                                    {t('status')}
                                </label>
                                <select
                                    name='status'
                                    value={userData.status || ""}
                                    onChange={onInputChanged}
                                    id="status" className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="active">{t('active')}</option>
                                    <option value="inactive">{t('inactive')}</option>
                                    <option value="pending">{t('pending')}</option>
                                </select>
                            </div>

                        </div>


                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">

                        <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-20 text-xl rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                            {t('save')}
                        </button>

                    </div>
                </form>
            </SkyeModal>
        </>
    );
}

export default UserModal;