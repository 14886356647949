

import React, { useState, useEffect } from "react";
import MainTemplate from "./MainTemplate";
import useDocumentTitle from "../hooks/userDocumentTitle";
import UsersTable from "../components/UsersTable";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "..";
import { useTranslation } from "react-i18next";
import { helpers } from "../helpers";
import CountryPhoneCodes from "../utils/country_codes";

const Users = () => {
    const { t } = useTranslation();
    useDocumentTitle(t('users'));
    const [users, setUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(false);

    const fetchUsers = async (name = "", phone = "") => {
        setUsersLoading(true);
        let users = [];
        //fetch bookings from firebase
        const usersRef = collection(db, 'users');

        var queries = [];

        if (name && name.length > 0 && phone.length === 0) {
            const searchWords = helpers.generateLiteSearchKeywords(name);
            queries.push(where('search_keywords', 'array-contains-any', searchWords));
        }

        if (phone && phone.length > 0 && name.length === 0) {
            queries.push(where('phone', 'in', [
                phone,
                parseInt(phone),
                ...CountryPhoneCodes.map((c) => {
                    //if started with zero remove it
                    if (phone.startsWith('0')) {
                        return c + phone.substring(1);
                    } else {
                        return c + phone;
                    }
                })
            ]));
        }

        queries.push(orderBy('created_at', 'desc'));


        const q = query(usersRef, ...queries);


        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                users.push({ ...doc.data(), id: doc.id });
            });
        });
        setUsers(users);
        setUsersLoading(false);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const onTableUpdated = (user) => {
        fetchUsers();
    }

    const onSearchNameButtonClicked = () => {
        const name = document.getElementById('client_name').value;
        fetchUsers(name);
    }

    const onSearchPhoneButtonClicked = () => {
        const phone = document.getElementById('client_phone').value;
        fetchUsers("", phone);
    }


    return (
        <MainTemplate title={t('users')}>

            {/* <!-- search filter --> */}
            <div className="grid grid-cols-2">
                {/* <!-- col-1 --> */}
                <div></div>
                {/* <!-- col-2 --> */}
                <div className="flex space-x-2 items-end w-full" style={{ textAlign: "right" }}>
                    <div className="inline-block">
                        <div className="relative">
                            <input
                                type="search"
                                id="client_name"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onSearchNameButtonClicked();
                                    }
                                }}
                                className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                                placeholder={t('search-name')} />
                            <button
                                onClick={onSearchNameButtonClicked}
                                type="button"
                                className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                <span className="sr-only">{t('search')}</span>
                            </button>
                        </div>
                    </div>

                    <div className="inline-block">
                        <div className="relative">
                            <input
                                type="search"
                                id="client_phone"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onSearchPhoneButtonClicked();
                                    }
                                }}
                                className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                                placeholder={t('phone')} />
                            <button
                                onClick={onSearchPhoneButtonClicked}
                                type="button"
                                className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                <span className="sr-only">{t('search')}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>



            <UsersTable users={users} loading={usersLoading} onTableUpdated={onTableUpdated} />

        </MainTemplate>
    );
}

export default Users;