
import useDocumentTitle from "../hooks/userDocumentTitle";
import forgotPasswordImage from "../assets/images/forgot-password-office.jpeg"
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { toast } from "react-toastify";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../index";
import { helpers } from "../helpers";
import VerifyCodeModal from "./modal/VerifyCodeModal";
import ResetPasswordModal from "./modal/ResetPasswordModal";


const ForgotPassword = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('forgot-password'));

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const [user, setUser] = useState({});

  const submit = async () => {
    if (email === "") {
      toast.error(t('email-is-required'));
      return;
    }

    const loading = toast.loading(t('checking-email'));

    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      toast.dismiss(loading);
      toast.error(t('email-not-found'));
      return;
    }

    //set first user
    querySnapshot.forEach((doc) => {
      setUser({ id: doc.id, ...doc.data() });
    });


    //gnerate 4 digit code
    const code = Math.floor(1000 + Math.random() * 9000);
    setCode(code);

    await helpers.sendMail(
      email,
      t('password-recovery'),
      `
      <p>${t('your-password-recovery-code-is')}:</p>
      <h1>${code}</h1>
      <p>${t('use-this-code-to-reset-your-password')}.</p>
      `
    );

    toast.dismiss(loading);
    toast.success(t('verification-code-sent-to-your-email'));
    setShowModal(true);
  }

  const openResetPasswordModal = () => {
    setShowModal(false);
    setShowResetModal(true);
  }


  return (
    <>
      <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
        <div
          className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800"
        >
          <div className="flex flex-col overflow-y-auto md:flex-row">
            <div className="h-32 md:h-auto md:w-1/2">
              <img
                aria-hidden="true"
                className="object-cover w-full h-full dark:hidden"
                src={forgotPasswordImage}
                alt="Office"
              />
              <img
                aria-hidden="true"
                className="hidden object-cover w-full h-full dark:block"
                src={forgotPasswordImage}
                alt="Office"
              />
            </div>
            <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
              <div className="w-full">
                <h1
                  className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200"
                >
                  {t('forgot-password-0')}
                </h1>
                <label className="block text-sm">
                  <span className="text-gray-700 dark:text-gray-400">{t('email-0')}</span>
                  <input
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="johndoe@example.com"
                  />
                </label>

                {/* <!-- You should use a button here, as the anchor is only used for the example  --> */}
                <button
                  onClick={submit}
                  className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"

                >
                  {t('recover-password')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

        {/* MODAL */}
        <VerifyCodeModal showModal={showModal} setShowModal={setShowModal} code={code} setCode={setCode} openResetPasswordModal={openResetPasswordModal} />
        <ResetPasswordModal showModal={showResetModal} setShowModal={setShowResetModal} user={user} />




    </>
  );
}

export default ForgotPassword;