const CountryPhoneCodes = [
  "1",
  "224",
  "234",
  "245",
  "93",
  "355",
  "33",
  "376",
  "244",
  "336",
];

export default CountryPhoneCodes;
