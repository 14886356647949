

import React, { useState, useEffect } from "react";
import MainTemplate from "./MainTemplate";
import useDocumentTitle from "../hooks/userDocumentTitle";
import HostRequestsTable from "../components/HostRequestsTable";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "..";
import { useTranslation } from "react-i18next";

const HostRequests = () => {
    const {t} = useTranslation();

    useDocumentTitle(t('host-requests'));

    const [requests, setHostRequests] = useState([]);
    const [requestsLoading, setHostRequestsLoading] = useState(false);
    const fetchHostRequests = async () => {
        setHostRequestsLoading(true);
        let requests = [];
        //fetch bookings from firebase
        const requestsRef = collection(db, 'host_requests');
        const q = query(requestsRef, orderBy('created_at', 'desc'));
        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                requests.push({ ...doc.data(), id: doc.id });
            });
        });
        setHostRequests(requests);
        setHostRequestsLoading(false);
    };

    useEffect(() => {
        fetchHostRequests();
    }, []);

    const onTableUpdated = (request) => {
        fetchHostRequests();
    }


    return (
        <MainTemplate title ={t('host-requests')}>

        <HostRequestsTable requests={requests} loading={requestsLoading} onTableUpdated={onTableUpdated} />

        </MainTemplate>
    );
}

export default HostRequests;