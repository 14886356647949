import { useContext, useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import { Timestamp, collection, doc, getDoc, getDocs, query, updateDoc } from "firebase/firestore";
import { db } from "../../index";
import { toast } from "react-toastify";
import { helpers } from "../../helpers";
import CircularLoader from "../../components/CircularLoader";
import { useTranslation } from "react-i18next";
import AppContext from "../../AppContext";

const HostRequestModal = ({ showModal, setShowModal, request, setHostRequest, onModalUpdated }) => {

    const {t} = useTranslation();

    const {user} = useContext(AppContext);

    const [requestData, setHostRequestData] = useState(request ?? { user: {} });

    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);

    const [regionsLoading, setRegionsLoading] = useState(true);
    const [citiesLoading, setCitiesLoading] = useState(true);
    const [municipalitiesLoading, setMunicipalitiesLoading] = useState(true);

    useEffect(() => {
        setHostRequestData(request ?? { user: {} });
    }, [request]);

    useEffect(() => {
        fetchRegions();
        fetchCities();
        fetchMunicipalities();
    }, []);

    const fetchRegions = async () => {
        setRegionsLoading(true);
        const q = query(collection(db, "regions"));
        const querySnapshot = await getDocs(q);
        let regions = [];
        querySnapshot.forEach((doc) => {
            regions.push({ id: doc.id, ...doc.data() });
        });
        //sort regions by name asc
        regions.sort((a, b) => {
            if (a.region_nom < b.region_nom) {
                return -1;
            }
            if (a.region_nom > b.region_nom) {
                return 1;
            }
            return 0;
        });

        setRegions(regions);
        setRegionsLoading(false);
    }

    const fetchCities = async () => {
        setCitiesLoading(true);
        const q = query(collection(db, "cities"));
        const querySnapshot = await getDocs(q);
        let cities = [];
        querySnapshot.forEach((doc) => {
            cities.push({ id: doc.id, ...doc.data() });
        });
        //sort cities by name asc
        cities.sort((a, b) => {
            if (a.prefecture_nom < b.prefecture_nom) {
                return -1;
            }
            if (a.prefecture_nom > b.prefecture_nom) {
                return 1;
            }
            return 0;
        });


        setCities(cities);
        setCitiesLoading(false);
    }

    const fetchMunicipalities = async () => {
        setMunicipalitiesLoading(true);
        const q = query(collection(db, "municipalities"));
        const querySnapshot = await getDocs(q);
        let municipalities = [];
        querySnapshot.forEach((doc) => {
            municipalities.push({ id: doc.id, ...doc.data() });
        });
        //sort municipalities by name asc
        municipalities.sort((a, b) => {
            if (a.commune_nom < b.commune_nom) {
                return -1;
            }
            if (a.commune_nom > b.commune_nom) {
                return 1;
            }
            return 0;
        });

        setMunicipalities(municipalities);
        setMunicipalitiesLoading(false);
    }

    const onInputChanged = (e) => {
        setHostRequestData({ ...requestData, [e.target.name]: e.target.value });
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();

        const loading = toast.loading(t('please-wait'));

        let formData = { ...requestData };


        // formData.region_slug = helpers.getSlug(formData.region ?? "");
        // formData.city_slug = helpers.getSlug(formData.city ?? "");
        // formData.municipality_slug = helpers.getSlug(formData.municipality ?? "");

        //remove user data
        delete formData.user;

        if (formData.id) { //update
            //remove id, created_at
            delete formData.id;
            delete formData.created_at;
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(requestData.user.full_name);

            //add to firebase
            const requestRef = doc(db, "host_requests", requestData.id);
            await updateDoc(requestRef, formData, { merge: true }).then(async () => {
                //get the updated data
                const docSnap = await getDoc(requestRef);
                if (docSnap.exists()) {

                    //update the user role to host
                    const userRef = doc(db, "users", requestData.user_id);
                    if (formData.status === "accepted") {
                        await updateDoc(userRef, { role: "host" }, { merge: true });
                    } else {
                        await updateDoc(userRef, { role: "user" }, { merge: true });
                    }

                    //push notification and email to user fcm_token (that there is an update on booking)
                    if (request.user_id) {
                        const userRef = doc(db, "users", request.user_id);
                        const userDocSnap = await getDoc(userRef);
                        
                        if (userDocSnap.exists()) {
                            const user = userDocSnap.data();
                            //push notification to user
                            if (user.fcm_token) {
                                await helpers.pushNotificationToToken(user.fcm_token, {
                                    title: t('host-request'),
                                    body: `${t('your-host-request-has-been')} ${formData.status}`,
                                }
                                );
                            }
                            //send email to user
                            if (user.email) {
                                await helpers.sendMail(user.email, t('host-request'), `${t('your-host-request-has-been')} <b>${formData.status}</b>. ${t('you-can-now-create-your-own-listings')}`);
                            }
                        }
                    }


                    setHostRequest({ ...docSnap.data(), id: docSnap.id });
                    setHostRequestData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success(t('updated-successfully'));
                } else {
                    toast.error(t('failed-to-update'));
                }
            }).catch((error) => {
                toast.error(t('failed-to-update'));
            });

            helpers.log(user, "update", `host request <b>(${requestData.user.full_name})</b> updated`);

        } else { //add
            //invalid data
            toast.error(t('invalid-data'));
        }

        toast.dismiss(loading);
        setShowModal(false);
        onModalUpdated(formData);
    }



    return (
        <>
            <SkyeModal title={requestData.id ? t('request-information') : t('new-hostrequest')} size="max-w-7xl" flex={false} showModal={showModal} setShowModal={setShowModal}>
                <form className="space-y-6" id="request-edit-form" onSubmit={onFormSubmit} method="POST" encType="multipart/form-data">
                    {/*body*/}
                    <div className="relative p-6 flex-auto">

                        {/* body */}

                        <div className="grid grid-cols-6 gap-6">
                            {
                                (requestData.user) && (
                                    <>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="full_name" className="block text-sm font-medium text-gray-700">
                                                {t('full-name')}
                                            </label>
                                            <input
                                                name='full_name'
                                                defaultValue={requestData.user.full_name || ""}
                                                readOnly
                                                disabled
                                                type="text" id="full_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('none')} />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                {t('email-address')}
                                            </label>
                                            <input
                                                name='email'
                                                defaultValue={requestData.user.email || ""}
                                                readOnly
                                                disabled
                                                type="email" className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('none')} />

                                        </div>
                                        {/* regions */}
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                                {t('region')}
                                            </label>
                                            {
                                                regionsLoading ?
                                                    (
                                                        <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                            <CircularLoader className={"w-full text-center"} />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <select
                                                            name='region'
                                                            defaultValue={requestData.user.region || ""}
                                                            readOnly
                                                            disabled
                                                            type="text" id="region" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        >
                                                            <option value="">None</option>
                                                            {
                                                                (() => {
                                                                    return regions.map((region, index) => (
                                                                        <option key={index} value={region.region_nom}>{region.region_nom}</option>
                                                                    ));
                                                                })()
                                                            }
                                                        </select>
                                                    )
                                            }
                                        </div>

                                        {/* cities */}
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                                {t('city')}
                                            </label>
                                            {
                                                citiesLoading ?
                                                    (
                                                        <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                            <CircularLoader className={"w-full text-center"} />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <select
                                                            name='city'
                                                            defaultValue={requestData.user.city || ""}
                                                            readOnly
                                                            disabled
                                                            type="text" id="city" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        >
                                                            <option value="">{t('none')}</option>
                                                            {
                                                                (() => {
                                                                    if (!requestData.user.region || requestData.user.region === "") {
                                                                        return [];
                                                                    }
                                                                    let region = regions.find(region => region.region_nom === requestData.user.region);
                                                                    if (!region) {
                                                                        return [];
                                                                    }
                                                                    //cities where region id is equal to the selected region id
                                                                    return cities.filter(city => city.region_id === region.id).map((city, index) => (
                                                                        <option key={index} value={city.prefecture_nom}>{city.prefecture_nom}</option>
                                                                    ));

                                                                })()
                                                            }
                                                        </select>
                                                    )
                                            }
                                        </div>

                                        {/* municipalities */}
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="municipality" className="block text-sm font-medium text-gray-700">
                                                {t('municipality')}
                                            </label>
                                            {
                                                municipalitiesLoading ?
                                                    (
                                                        <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                            <CircularLoader className={"w-full text-center"} />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <select
                                                            name='municipality'
                                                            defaultValue={requestData.user.municipality || ""}
                                                            readOnly
                                                            disabled
                                                            type="text" id="municipality" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        >
                                                            <option value="">{t('none')}</option>
                                                            {
                                                                (() => {
                                                                    if (!requestData.user.city || requestData.user.city === "") {
                                                                        return [];
                                                                    }
                                                                    let city = cities.find(city => city.prefecture_nom === requestData.user.city);
                                                                    if (!city) {
                                                                        return [];
                                                                    }
                                                                    //municipalities where city id is equal to the selected city id
                                                                    return municipalities.filter(municipality => municipality.prefecture_id === city.id).map((municipality, index) => (
                                                                        <option key={index} value={municipality.commune_nom}>{municipality.commune_nom}</option>
                                                                    ));
                                                                })()
                                                            }
                                                        </select>
                                                    )
                                            }
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                                {t('address')}
                                            </label>
                                            <input
                                                name='address'
                                                defaultValue={requestData.user.address || ""}
                                                readOnly
                                                disabled
                                                type="text" id="address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('none')} />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                                {t('phone')}
                                            </label>
                                            <input
                                                name='phone'
                                                defaultValue={requestData.user.phone || ""}
                                                readOnly
                                                disabled
                                                type="text" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('none')} />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="zip_code" className="block text-sm font-medium text-gray-700">
                                                {t('zip')}
                                            </label>
                                            <input
                                                name='zip_code'
                                                defaultValue={requestData.user.zip_code || ""}
                                                readOnly
                                                disabled
                                                type="text" id="zip_code" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('none')} />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                                                {t('gender')}
                                            </label>
                                            <select
                                                name="gender"
                                                value={requestData.user.gender || ""}
                                                readOnly
                                                disabled
                                                id="gender" className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <option>{t('none-0')}</option>
                                                <option value="Male">{t('male')}</option>
                                                <option value="Female">{t('female')}</option>
                                            </select>
                                        </div>

                                        {/* <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
                                                Date of Birth
                                            </label>
                                            <input
                                                name='dob'
                                                defaultValue={requestData.user.dob || ""}
                                                readOnly
                                                disabled
                                                type="date" id="dob" className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                        </div> */}

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                                                {t('role')}
                                            </label>
                                            <select
                                                name='role'
                                                defaultValue={requestData.user.role || ""}
                                                readOnly
                                                disabled
                                                id="role" className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <option value="">{t('none-1')}</option>
                                                <option value="user">{t('user')}</option>
                                                <option value="host">{t('host')}</option>
                                                <option value="admin">{t('admin')}</option>
                                                <option value="super-admin">{t('super-admin')}</option>
                                            </select>
                                        </div>
                                    </>
                                )
                            }



                        </div>


                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                        <div className="mr-5 w-80">
                            {/* <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                                    Status
                                </label> */}
                            <select
                                name='status'
                                value={requestData.status || ""}
                                onChange={onInputChanged}
                                id="status" className="bg-yellow-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="">{t('none')}</option>
                                <option value="pending">{t('pending')}</option>
                                <option value="accepted">{t('accepted')}</option>
                                <option value="cancelled">{t('cancelled')}</option>
                            </select>
                        </div>
                        <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-20 text-xl rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                            {t('save')}
                        </button>

                    </div>
                </form>
            </SkyeModal>
        </>
    );
}

export default HostRequestModal;