

import React, { useState, useEffect } from "react";
import MainTemplate from "./MainTemplate";
import useDocumentTitle from "../hooks/userDocumentTitle";
import PostsTable from "../components/PostsTable";
import { db } from "..";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { helpers } from "../helpers";
import { useTranslation } from "react-i18next";

const Posts = () => {
    const {t} = useTranslation();
    useDocumentTitle(t('posts'));
    const [posts, setPosts] = useState([]);
    const [postsLoading, setPostsLoading] = useState(false);
    const fetchPosts = async (title = "") => {
        setPostsLoading(true);
        let posts = [];
        //fetch bookings from firebase
        const postsRef = collection(db, 'posts');

        var queries = [
            orderBy('created_at', 'desc')
        ];

        if (title && title.length > 0) {
            const searchWords = helpers.generateLiteSearchKeywords(title);
            queries.push(where('search_keywords', 'array-contains-any', searchWords));
        }

        const q = query(postsRef, ...queries);

        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                posts.push({ ...doc.data(), id: doc.id });
            });
        });
        setPosts(posts);
        setPostsLoading(false);
    };

    useEffect(() => {
        fetchPosts();
    }, []);


    const onTableUpdated = (post) => {
        fetchPosts();
    }

    const onSearchNameButtonClicked = () => {
        const name = document.getElementById('post_name').value;
        fetchPosts(name);
    }


    return (
        <MainTemplate title={t('posts')}>

             {/* <!-- search filter --> */}
             <div className="grid grid-cols-2">
                {/* <!-- col-1 --> */}
                <div></div>
                {/* <!-- col-2 --> */}
                <div className="flex space-x-2 items-end w-full" style={{ textAlign: "right" }}>
                    <div className="sm:w-64"></div>
                    <div className="inline-block">
                        <div className="relative">
                            <input
                                type="search"
                                id="post_name"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onSearchNameButtonClicked();
                                    }
                                }}
                                className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" 
                                placeholder={t('search-name')} />
                            <button
                                onClick={onSearchNameButtonClicked}
                                type="button"
                                className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                <span className="sr-only">{t('search')}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <PostsTable posts={posts} onTableUpdated={onTableUpdated} loading={postsLoading} />

        </MainTemplate>
    );
}

export default Posts;