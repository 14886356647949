/* eslint-disable eqeqeq */

import SkyeModal from "../../components/SkyeModal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../..";

const ResetPasswordModal = ({ showModal, setShowModal, user }) => {

    const { t } = useTranslation();

    const [formData, setFormData] = useState({});

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();

        if (!user.id) {
            toast.error(t('account-not-found-please-try-again'));
            return;
        }

        if (formData.password !== formData.confirm_password) {
            toast.error(t('password-and-confirm-password-does-not-match'));
            return;
        }

        const loading = toast.loading(t('updating-password'));

        try {
            const docRef = doc(db, "users", user.id);

            await updateDoc(docRef, {password: formData.password});
            toast.success(t('password-updated'));
            window.location.href = `/admin/login?message=${t('password-updated-successfully-please-login-with-your-new-password')}`;
        }
        catch (error) {
            toast.error(t('error-updating-password'));
        }
        finally {
            toast.dismiss(loading);
        }

    }


    return (
        <>
            <SkyeModal title="Verify" size="max-w-sm" flex={false} showModal={showModal} setShowModal={setShowModal}>
                <form autoComplete="off" className="space-y-6" id="type-edit-form" onSubmit={onFormSubmit} method="POST" encType="multipart/form-data">
                    {/*body*/}
                    <div className="flex flex-col gap-4 relative px-6 pt-6 pb-2 flex-auto">

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-900 dark:text-gray-300">{t('new-password')}</label>
                            <input
                                name="password"
                                value={formData.password || ""}
                                onChange={onChange}
                                type="password" id="password" className="bg-gray-50 text-left border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder={t('new-password')} required />
                        </div>
                        <div>
                            <label htmlFor="confirm_password" className="block text-sm font-medium text-gray-900 dark:text-gray-300">{t('confirm-password')}</label>
                            <input
                                name="confirm_password"
                                value={formData.confirm_password || ""}
                                onChange={onChange}
                                type="password" id="confirm_password" className="bg-gray-50 text-left border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder={t('confirm-password')} required />
                        </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <span id="submit-text">{t('reset-password')}</span>
                        </button>
                    </div>
                </form>
            </SkyeModal>
        </>
    );
}

export default ResetPasswordModal;