

const toFrechLabel = (role) => {
    switch (role) {
        case "admin":
            return "Administrateur";
        case "user":
            return "Utilisateur";
        case "super-admin":
            return "Super Administrateur";
        case "host":
            return "Hôte";
        default:
            return role;
    }
}

const toEnglishLabel = (role) => {
    switch (role) {
        case "admin":
            return "Admin";
        case "user":
            return "User";
        case "super-admin":
            return "Super Admin";
        case "host":
            return "Host";
        default:
            return role;
    }
}


const RoleLabel = ({ role, toUpperCase = false, lang = "fr", className = null }) => {
    var label = role;
    switch (lang) {
        case "fr":
            label = toFrechLabel(role);
            break;
        case "en":
            label = toEnglishLabel(role);
            break;
        default:
            break;
    }

    return (
        <span className={className}>
            {toUpperCase ? label.toUpperCase() : label}
        </span>
    );
}

export default RoleLabel;