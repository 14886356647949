import { helpers } from "../helpers";

const getStatusLanguage = (status) => {
    //to french
    switch (status) {
        case "Pending":
        case "pending":
            return "En attente";
        case "Completed":
        case "completed":
            return "Terminé";
        case "Cancelled":
        case "cancelled":
            return "Annulé";
        case "Approved":
        case "approved":
            return "Approuvé";
        case "Publish":
        case "publish":
            return "Publié";
        case "Published":
        case "published":
            return "Publié";
        case "Taken":
        case "taken":
            return "Pris";
        case "denied":
        case "Denied":
            return "Refusé";
        case "active":
        case "Active":
            return "Actif";
        case "inactive":
        case "Inactive":
            return "Inactif";
        default:
            return status || "";
    }
};

const StatusLabel = ({ status }) => {
    //default status bg and text color
    let bgColor = "bg-gray-100";
    let textColor = "text-gray-700";

    //change bg and text color based on status
    switch (status) {
        case "Pending":
        case "pending":
            bgColor = "bg-yellow-100";
            textColor = "text-yellow-700";
            break;
        case "Completed":
        case "completed":
            bgColor = "bg-green-100";
            textColor = "text-green-700";
            break;
        case "Cancelled":
        case "cancelled":
            bgColor = "bg-red-100";
            textColor = "text-red-700";
            break;
        case "Approved":
        case "approved":
            bgColor = "bg-blue-100";
            textColor = "text-blue-700";
            break;
        case "Publish":
        case "publish":
            bgColor = "bg-green-100";
            textColor = "text-green-700";
            break;
        case "Published":
        case "published":
            bgColor = "bg-green-100";
            textColor = "text-green-700";
            break;
        case "Taken":
        case "taken":
            bgColor = "bg-green-100";
            textColor = "text-green-700";
            break;
        case "denied":
        case "Denied":
            bgColor = "bg-red-100";
            textColor = "text-red-700";
            break;
        case "active":
        case "Active":
            bgColor = "bg-green-100";
            textColor = "text-green-700";
            break;
        case "inactive":
        case "Inactive":
            bgColor = "bg-red-100";
            textColor = "text-red-700";
            break;
        default:
            break;
    }



    return (
        <span className={`px-2 py-1 font-semibold leading-tight ${textColor} ${bgColor} rounded-full`}>
            {(status === "approved") ? "✓ " : ""}
            {helpers.capitalize(getStatusLanguage(status))}
            {(status === "completed") ? " ✓✓" : ""}
        </span>
    );
}

export default StatusLabel;