import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "./hooks/useAuth";
import ProtectedLayout from "./components/ProtectedLayout";
import NoPage from "./pages/NoPage";
import AppContext from "./AppContext";
import Home from "./pages/Home";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Properties from "./pages/Properties";
import Bookings from "./pages/Bookings";
import Posts from "./pages/Posts";
import Users from "./pages/Users";
import Settings from "./pages/Settings";
import PushNotifications from "./pages/PushNotifications";
import Profile from "./pages/Profile";
import Types from "./pages/Types";
import Facilities from "./pages/Facilities";
import Amenities from "./pages/Amenities";
import EmailVerification from "./pages/EmailVerification";
import HostRequests from "./pages/HostRequests";
import PostPage from "./pages/front/PostPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Logs from "./pages/Logs";

function App() {
  // global variables -- used in AppContext
  const { user, token, login, logout, loading, tokenLogin, tokenLogout } =
    useAuth(null);

  return (
    <AppContext.Provider
      value={{ user, token, login, logout, loading, tokenLogin, tokenLogout }}
    >
      <BrowserRouter>
        <Routes>
          {/* NO PROTECTION */}
          <Route index element={<Landing />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/host/login" element={<Login />} />
          <Route path="/admin/forgot-password" element={<ForgotPassword />} />
          <Route path="/host/forgot-password" element={<ForgotPassword />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/post">
            <Route path=":slug" element={<PostPage />} />
          </Route>

          {/* PROTECTED */}

          <Route path="/admin" element={<ProtectedLayout roles={['super-admin', 'admin', 'host']} />}>
            <Route index element={<Home />} />
            <Route path="properties" element={<Properties />} />
            <Route path="bookings" element={<Bookings />} />
            <Route path="profile" element={<Profile />} />
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route path="/admin" element={<ProtectedLayout roles={['super-admin', 'admin']} />}>
            <Route path="types" element={<Types />} />
            <Route path="facilities" element={<Facilities />} />
            <Route path="amenities" element={<Amenities />} />
            <Route path="host-requests" element={<HostRequests />} />
            <Route path="posts" element={<Posts />} />
            <Route path="notifications" element={<PushNotifications />} />
          </Route>
          <Route path="/admin" element={<ProtectedLayout roles={['super-admin']} />}>
            <Route path="users" element={<Users />} />
            <Route path="settings" element={<Settings />} />
            <Route path="logs" element={<Logs />} />
          </Route>

          <Route path="*" element={<NoPage />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
