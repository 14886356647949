import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ErrorHandlerComponent = (props) => {
    const { error, errorMessage } = props;

    const {t} = useTranslation();

    var errorCode = 401;
    var message = errorMessage ?? "";

    if (error == null) {
        toast.error(t('unknown-error'));
        message = t('unknown-error');
    } else if (error.response) {
        errorCode = error.response.status;
        if (error.response.status === 401 || error.response.status === 403) {
            toast.error(t('you-are-not-authorized'));
            return <Navigate to="/admin/login" />;
        } else if (error.response.status === 404) {
            message = error.code +  ": 404 " + t('error') + ", " + error.config.url;
        }
    }

    return (
        <>
            <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
                <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                    <div className="max-w-md text-center">
                        <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
                            <span className="sr-only">{t('error')}</span>{errorCode}
                        </h2>
                        <p className="text-2xl font-semibold md:text-3xl">{t('sorry-we-couldnt-process-your-command')}</p>
                        <p className="mt-4 mb-8 dark:text-gray- font-bold italic">{message}</p>
                        <p className="mt-4 mb-8 dark:text-gray-400">{t('but-dont-worry-you-can-login-again-if-you-still-have-access')}</p>
                        <Link rel="noopener noreferrer" to="/admin/login" className="px-8 py-3 bg-primary-900 text-white font-semibold rounded-full dark:bg-violet-400 dark:text-gray-900">{t('login-again')}</Link>
                    </div>
                </div>
            </section>
        </>
    );
};