/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */

import useDocumentTitle from "../hooks/userDocumentTitle";
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";

import { Button, Img, List, Text } from "../components";
import { useTranslation } from "react-i18next";

const Landing = () => {

    const { t } = useTranslation();

    useDocumentTitle('Welcome');

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [currentFrame, setCurrentFrame] = useState("home");

    const changeFrame = (frame) => {
        setCurrentFrame(frame);
        //scroll to each frame with animation
        const element = document.getElementById(frame);
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    }

    return (


        <>
            {/* menu backdrop */}
            <div className={`fixed top-0 left-0 z-20 w-screen h-screen bg-black opacity-40 hidden ${isMenuOpen ? 'mymd:block' : ''}`}
                onClick={() => setIsMenuOpen(!isMenuOpen)}></div>

            <div className="bg-white flex flex-col font-poppins items-start justify-start mx-auto w-auto mysm:w-full mymd:w-full">
                {/* frame 1 (Header) */}
                <div id="home" className="bg-[#6ae2d0] flex flex-col gap-[13px] items-center justify-end p-[30px] mysm:px-5 w-full">
                    <header className="flex flex-row items-center justify-between px-[100px] mymd:px-0 mt-1 w-full">
                        <Img
                            className="h-[83px] mymd:h-auto object-contain w-[8%]"
                            src="images/img_logo21.png"
                            alt="logoTwentyOne"
                        />
                        {/* menu */}
                        <div className={`flex mysm:flex-1 flex-row mysm:gap-0 gap-[100px] items-start justify-start mb-[23px] mymd:ml-[0] mymd:mt-0 mt-[29px] w-auto 
                                ${isMenuOpen ? '' : 'mysm:hidden'}
                                mysm:w-[60%]
                                mysm:fixed
                                mysm:top-0
                                mysm:right-0
                                mysm:h-full
                                mysm:bg-white
                                mysm:z-30
                                mysm:flex-col
                                mysm:px-0
                                `}>
                            {/* close menu button */}
                            <div className="mysm:flex flex-row gap-2 items-center justify-end w-full hidden pr-4 pt-4">
                                <button className="inline"
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-10 h-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>


                            <Button onClick={() => changeFrame("home")} className={`flex flex-col items-center mysm:items-start mysm:justify-start mysm:py-3 mysm:px-4 ${currentFrame === "home" && "font-bold"} bg-transparent cursor-pointer leading-[normal] min-w-[71px] mysm:min-w-full text-[#314b66] text-center mysm:hover:bg-gray-100 mysm:w-full`}>
                                {t('home')}
                                {currentFrame === "home" && (<Img className="h-0.5 w-5 mysm:w-10" src="images/img_vector1.svg" alt="vectorOne" />)}
                            </Button>
                            <Button onClick={() => changeFrame("about-us")} className={`flex flex-col items-center mysm:items-start mysm:justify-start mysm:py-3 mysm:px-4 bg-transparent cursor-pointer leading-[normal] min-w-[71px] mysm:min-w-full ${currentFrame === "about-us" && "font-bold"} text-[#314b66] text-center mysm:hover:bg-gray-100 mysm:w-full`}>
                                {t('about-us')}
                                {currentFrame === "about-us" && (<Img className="h-0.5 w-5 mysm:w-10" src="images/img_vector1.svg" alt="vectorOne" />)}
                            </Button>
                            <Button onClick={() => changeFrame("why-us")} className={`flex flex-col items-center mysm:items-start mysm:justify-start mysm:py-3 mysm:px-4 bg-transparent cursor-pointer leading-[normal] min-w-[57px] mysm:min-w-full ${currentFrame === "why-us" && "font-bold"} text-[#314b66] text-center mysm:hover:bg-gray-100 mysm:w-full`}>
                                {t('why-us')}
                                {currentFrame === "why-us" && (<Img className="h-0.5 w-5 mysm:w-10" src="images/img_vector1.svg" alt="vectorOne" />)}
                            </Button>
                            <Button onClick={() => changeFrame("contact")} className={`flex flex-col items-center mysm:items-start mysm:justify-start mysm:py-3 mysm:px-4 bg-transparent cursor-pointer leading-[normal] min-w-[65px] mysm:min-w-full ${currentFrame === "contact" && "font-bold"} text-[#314b66] text-center mysm:hover:bg-gray-100 mysm:w-full`}>
                                {t('contact')}
                                {currentFrame === "contact" && (<Img className="h-0.5 w-5 mysm:w-10" src="images/img_vector1.svg" alt="vectorOne" />)}
                            </Button>
                        </div>
                        <button className="mysm:flex flex-row gap-2 items-center justify-center hidden"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                    </header>
                    <div className="h-[920px] mymd:h-[685px] mysm:h-auto max-w-[1166px] mx-auto mymd:px-5 mysm:px-2 relative w-full">
                        <Img
                            className="absolute mysm:hidden h-[522px] object-contain right-[0] top-[0] w-[35%]"
                            src="images/iloger-mockup.png"
                            alt="unsplashctuwe7b"
                        />
                        <div className="absolute mysm:relative bottom-[0] flex flex-col items-start justify-start left-[0] w-[67%] mysm:w-full">
                            <Text
                                className="leading-[74.00px] mymd:text-5xl text-[64px] text-black tracking-[0.16px] w-full"
                                size="txtRubikSemiBold64"
                            >
                                {t('find-the-best-friendly-homes-just-for-you')}
                            </Text>
                            <Text
                                className="leading-[30.00px] mt-11 text-base text-[#314b66]_8c w-[71%] mysm:w-full"
                                size="txtPoppinsLight16"
                            >
                                {t('providing-housing-for-you-and-family-with-a-variety-of-choices-and-strategic-locations-in-your-city-in-an-easier-way')}
                            </Text>
                            <div className="flex mymd:flex-col flex-row font-poppins gap-[42px] gap-x-0 items-start justify-start mt-9 w-[89%] mymd:w-full">
                                <Text
                                    className="mymd:mt-0 mt-6 text-2xl mymd:text-[22px] text-[#314b66] mysm:text-xl mysm:text-center mysm:w-full"
                                    size="txtPoppinsSemiBold24"
                                >
                                    {t('download-app')}
                                </Text>
                                <div className="flex mysm:flex-col flex-row gap-3 items-end mysm:items-center justify-start w-[75%] mymd:w-full">
                                    <a href="https://play.google.com/store/apps/details?id=com.iloger.mobile"><Img
                                        className="h-[65px] mysm:mt-0 mt-1.5 object-contain rounded-xl"
                                        src="images/playstore-fr.png"
                                        alt="toppngTwo"
                                    />
                                    </a>
                                    <a href="https://apps.apple.com/us/app/iloger/id6478977850"><Img
                                        className="h-[65px] object-contain rounded-xl"
                                        src="images/appstore-fr.png"
                                        alt="pngitem1144050"
                                    />
                                    </a>
                                </div>
                                <Img
                                    className="object-contain mysm:block hidden"
                                    src="images/iloger-mockup.png"
                                    alt="unsplashctuwe7b"
                                />
                            </div>
                            <div className="flex flex-row font-poppins mysm:gap-5 gap-[72px] items-start justify-start mysm:justify-center mt-[71px] w-[76%] mymd:w-full">
                                <div className="flex flex-col items-center justify-center w-[149px] mysm:w-auto">
                                    <Text
                                        className="mymd:text-5xl mysm:text-[30px] text-[56px] text-[#314b66] w-auto"
                                        size="txtPoppinsSemiBold56"
                                    >
                                        <span className="text-[#314b66] font-poppins text-left font-semibold">
                                            50
                                        </span>
                                        <span className="text-indigo-300 font-poppins text-left font-semibold">
                                            +
                                        </span>
                                    </Text>
                                    <Text
                                        className="leading-[30.00px] max-w-[142px] mymd:max-w-full text-base mysm:text-sm text-[#314b66]_8c"
                                        size="txtPoppinsLight16"
                                    >
                                        {/* {t('residential-group-has-joined')} */}
                                        Avec plus de 50 résidences rejointes à ce jour, vous rejoignez une communauté dynamique et en pleine croissance.
                                    </Text>
                                </div>
                                <div className="flex flex-col gap-[5px] items-start justify-start w-[148px] mysm:w-auto">
                                    <Text
                                        className="mymd:text-5xl mysm:text-[30px] text-[56px] text-[#314b66] w-auto"
                                        size="txtPoppinsSemiBold56"
                                    >
                                        <span className="text-[#314b66] font-poppins text-left font-semibold">
                                            5{" "}
                                        </span>
                                        <span className="text-indigo-300 font-poppins text-left text-base font-bold">
                                            {t('years')}
                                        </span>
                                    </Text>
                                    <Text
                                        className="text-base mysm:text-sm text-[#314b66]_8c w-auto"
                                        size="txtPoppinsLight16"
                                    >
                                        {/* {t('experienced')}{" "} */}
                                        Profitez de notre expertise de plus de 5 ans dans l'immobilier pour trouver la solution parfaite pour vous et votre famille.
                                    </Text>
                                </div>
                                <div className="flex flex-col items-start justify-start w-[149px] mysm:w-auto">
                                    <Text
                                        className="mymd:text-5xl mysm:text-[30px] text-[56px] text-[#314b66] w-auto"
                                        size="txtPoppinsSemiBold56"
                                    >
                                        <span className="text-[#314b66] font-poppins text-left font-semibold">
                                            99
                                        </span>
                                        <span className="text-indigo-300 font-poppins text-left font-semibold">
                                            +
                                        </span>
                                    </Text>
                                    <Text
                                        className="leading-[30.00px] max-w-[142px] mymd:max-w-full text-base mysm:text-sm text-[#314b66]_8c"
                                        size="txtPoppinsLight16"
                                    >
                                        {/* {t('properties-available-in-various-cities')} */}
                                        Découvrez plus de 99 propriétés disponibles dans différentes villes, offrant une variété d'options pour répondre à vos préférences de localisation et de budget.
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* frame 2 (About us) */}
                <div id="about-us" className="bg-white flex flex-col items-start justify-start p-[53px] mymd:px-10 mysm:px-5 w-full">

                    <div className="flex flex-col font-rubik items-center justify-start max-w-[1192px] mb-0.5 mx-auto pr-0.5 py-0.5 w-full">
                        <Text
                            className="text-4xl mysm:text-[32px] mymd:text-[34px] text-[#314b66] w-full"
                            size="txtPoppinsSemiBold36"
                        >
                            {t('about-us-0')}
                        </Text>
                        <div className="flex mymd:flex-col flex-row mymd:gap-[53px] items-end justify-between mb-[26px] w-[99%] mymd:w-full">
                            <div className="h-[611px] mysm:h-auto mymd:mt-0 mt-[29px] relative w-[57%] mymd:w-full">
                                <Text
                                    className="leading-[28.00px] m-auto mysm:text-[23px] mymd:text-[25px] text-[21px] text-black tracking-[0.20px] w-full"
                                    size="txtRubikSemiBold27"
                                >
                                    {t('our-goal-is-at-the-heart-of-all-that-we-do-we-make-our-clients-happiness-our-sole-priority')}
                                </Text>
                                {/* <div className="absolute mysm:relative flex flex-col font-poppins inset-x-[0] items-center justify-start mx-auto top-[16%] w-full">
                                    <div className="bg-gray-50 flex flex-col items-start justify-center max-w-[668px] p-5 w-full">
                                        <Text
                                            className="leading-[26.00px] text-base text-blue_gray-500 tracking-[0.50px]"
                                            size="txtPoppinsRegular16"
                                        >
                                            <span className="text-teal-900 font-poppins text-left text-xl font-bold">
                                                <>
                                                    {t('who-we-are')}
                                                    <br />
                                                </>
                                            </span>
                                            <span className="text-blue_gray-500 font-poppins text-left text-xl font-normal">
                                                <>
                                                    <br />
                                                    {t('we-and-39-re-more-than-just-an-agency-we-and-39-re-your-trusted-partners-on-your-journey-to-finding-your-dream-home-with-a-passion-for-exceptional-service-we-and-39-ve-built-our-reputation-on-integrity-expertise-and-unwavering-commitment-to-your-satisfaction')}
                                                </>
                                            </span>
                                        </Text>
                                    </div>
                                </div> */}
                                {/* <div className="absolute mysm:relative bottom-[10%] flex flex-col font-poppins inset-x-[0] items-center justify-start mx-auto w-full">
                                    <div className="bg-gray-50 flex flex-col items-start justify-center max-w-[668px] p-5 w-full">
                                        <Text
                                            className="leading-[26.00px] text-base text-blue_gray-500 tracking-[0.50px]"
                                            size="txtPoppinsRegular16"
                                        >
                                            <span className="text-teal-900 font-poppins text-left text-xl font-bold">
                                                <>
                                                    {t('your-dreams-our-mission')}
                                                    <br />
                                                </>
                                            </span>
                                            <span className="text-blue_gray-500 font-poppins text-left text-xl font-normal">
                                                <>
                                                    <br />
                                                    {t('whether-you-and-39-re-a-first-time-buyer-seasoned-investor-or-looking-to-sell-we-are-dedicated-to-making-your-real-estate-journey-a-seamless-and-successful-one-your-happiness-is-not-just-a-goal-it-and-39-s-our-commitment')}
                                                </>
                                            </span>
                                        </Text>
                                    </div>
                                </div> */}
                                <div className="absolute mysm:relative flex flex-col font-poppins inset-x-[0] items-center justify-start mx-auto top-[16%] w-full">
                                    <div className="bg-gray-50 flex flex-col items-start justify-center max-w-[668px] p-5 w-full">
                                        <Text
                                            className="leading-[26.00px] text-base text-blue_gray-500 tracking-[0.50px]"
                                            size="txtPoppinsRegular16"
                                        >
                                            {/* <span className="text-teal-900 font-poppins text-left text-xl font-bold">
                                                <>
                                                    {t('your-dreams-our-mission')}
                                                    <br />
                                                </>
                                            </span> */}
                                            <span className="text-blue_gray-500 font-poppins text-left text-xl font-normal">
                                                <>
                                                    <br />
                                                    {t('whether-you-and-39-re-a-first-time-buyer-seasoned-investor-or-looking-to-sell-we-are-dedicated-to-making-your-real-estate-journey-a-seamless-and-successful-one-your-happiness-is-not-just-a-goal-it-and-39-s-our-commitment')}
                                                </>
                                            </span>
                                        </Text>
                                    </div>
                                </div>
                            </div>
                            <Img
                                className="h-[640px] mymd:h-[500px] mymd:w-full object-cover rounded-[16px]"
                                src="images/img_rectangle3.png"
                                alt="rectangleThree"
                            />
                        </div>
                    </div>
                </div>
                {/* frame 3 (Why Us) */}
                <div id="why-us" className="bg-[#6ae2d0] flex flex-col items-center justify-start p-[67px] mymd:px-10 mysm:px-5 w-full">
                    <div className="flex flex-col items-start justify-start max-w-[1193px] mx-auto w-full">
                        <Text className="text-base text-blue-900" size="txtPoppinsMedium16">
                            {t('consumer-choice')}
                        </Text>
                        <Text
                            className="mt-3 text-4xl mysm:text-[32px] mymd:text-[34px] text-[#314b66]"
                            size="txtPoppinsSemiBold36"
                        >
                            {t('why-us-0')}
                        </Text>
                        <div className="flex mymd:flex-col flex-row mymd:gap-5 items-start justify-evenly mt-[22px] w-full">
                            <div className="mymd:h-[490px] h-[550px] mymd:mt-0 mt-[37px] relative w-[45%] mymd:w-full">
                                <Img
                                    className="absolute h-[455px] left-[0] object-cover rounded-lg top-[0] w-[63%]"
                                    src="images/img_unsplashkqolr8oiqlu.png"
                                    alt="unsplashkqolr8o"
                                />
                                <Img
                                    className="absolute bottom-[0] h-[490px] object-cover right-[0] rounded-[11px] w-[69%]"
                                    src="images/img_unsplashuv81e0oxxwq.png"
                                    alt="unsplashuv81e0o"
                                />
                            </div>
                            <div className="flex mymd:flex-1 flex-col gap-[13px] items-center justify-start w-[56%] mymd:w-full">
                                <div className="flex flex-col gap-[15px] items-start justify-center max-w-[668px] p-5 w-full">
                                    <Img
                                        className="h-[45px] w-[37px]"
                                        src="images/img_location.svg"
                                        alt="location"
                                    />
                                    <Text
                                        className="mysm:text-2xl mymd:text-[26px] text-[28px] text-teal-900 tracking-[0.50px] w-auto"
                                        size="txtPoppinsBold28"
                                    >
                                        {t('comfortable')}
                                    </Text>
                                    <Text
                                        className="leading-[26.00px] max-w-[626px] mymd:max-w-full text-base text-blue_gray-500 tracking-[0.50px]"
                                        size="txtPoppinsRegular16"
                                    >
                                        <>
                                            {t('enjoy-lifestyle-amenities-designed-to-provide-every-homeowners-modern-comfort-a-stone-and-39-s-throw-away-from-schools-churches-and-hospitals')}
                                        </>
                                    </Text>
                                </div>
                                <div className="flex flex-col gap-[15px] items-start justify-center max-w-[666px] p-5 w-full">
                                    <Img
                                        className="h-[46px] w-[38px]"
                                        src="images/img_close.svg"
                                        alt="close"
                                    />
                                    <div className="flex flex-col items-start justify-start w-full">
                                        <Text
                                            className="mysm:text-2xl mymd:text-[26px] text-[28px] text-teal-900 tracking-[0.50px] w-auto"
                                            size="txtPoppinsBold28"
                                        >
                                            {t('best-price')}
                                        </Text>
                                    </div>
                                    <Text
                                        className="leading-[26.00px] max-w-[626px] mymd:max-w-full text-base text-blue_gray-500 tracking-[0.50px]"
                                        size="txtPoppinsRegular16"
                                    >
                                        {t('not-sure-what-you-should-be-charging-for-your-property-let-us-do-the-numbers-for-you-contact-us-today-for-a-free-rental-appraisal-on-your-home')}
                                        <br/>
                                        <br/>
                                        Vous cherchez à déterminer le meilleur prix pour votre propriété, nous confier la gestion ou la commercialisation de votre bien ? Contactez-nous dès aujourd'hui.
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* frame 5 (Download App) */}
                <div className="bg-white flex flex-col font-josefinsans items-center justify-start p-[85px] mymd:px-10 mysm:px-5 w-full">
                    <div className="flex mymd:flex-col flex-row mymd:gap-10 items-center justify-between max-w-[1151px] mb-[67px] mt-[86px] mx-auto w-full">
                        <div className="flex flex-col items-start justify-start">
                            <Text
                                className="text-4xl mysm:text-[32px] mymd:text-[34px] text-[#314b66]_01 uppercase mymd:text-center w-full"
                                size="txtJosefinSansBold36"
                            >
                                {t('download-app-now')}
                            </Text>
                            <Text
                                className="leading-[24.00px] mt-[11px] text-2xl mymd:text-[22px] text-gray-600 mysm:text-xl w-full mymd:text-center"
                                size="txtJosefinSansRegular24"
                            >
                                <span className="text-gray-600 font-josefinsans text-left font-normal">
                                    {t('this-is-where-you-can-find-a-dream-home-of-your-choice-without-stress')}{" "}
                                </span>
                                <span className="text-gray-600 font-josefinsans text-left font-bold">
                                   {t('find-the-right-one-for-you')}
                                </span>
                            </Text>
                            <div className="flex mysm:flex-col flex-row gap-5 mysm:gap-11 items-center justify-start mymd:justify-center mt-[30px] w-full">
                                <a href="https://play.google.com/store/apps/details?id=com.iloger.mobile"><Img
                                    className="h-[82px] object-cover rounded-xl"
                                    src="images/playstore-fr.png"
                                    alt="toppngTwo_One"
                                />
                                </a>
                                <a href="https://apps.apple.com/us/app/iloger/id6478977850"><Img
                                    className="h-[82px] object-cover rounded-xl"
                                    src="images/appstore-fr.png"
                                    alt="pngitem1144050_One"
                                />
                                </a>
                            </div>
                        </div>
                        <Img
                            className="h-[413px]"
                            src="images/img_appdesgin.svg"
                            alt="appdesgin"
                        />
                    </div>
                </div>
                {/* frame 6 (Contact us) */}
                <div id="contact" className="bg-[#6ae2d0] flex flex-col font-poppins items-center justify-end pt-[47px] w-full">
                    <div className="flex flex-col gap-12 items-center justify-start w-full">
                        <div className="flex mymd:flex-col flex-row mymd:gap-10 gap-20 items-start justify-start max-w-[1165px] mx-auto mymd:px-5 w-full">
                            <div className="flex mymd:flex-1 flex-col mymd:gap-10 gap-[62px] items-start justify-start w-[59%] mymd:w-full">
                                <Text
                                    className="text-4xl mysm:text-[32px] mymd:text-[34px] text-[#314b66]"
                                    size="txtPoppinsSemiBold36"
                                >
                                    {t('contact-us')}
                                </Text>
                                <List
                                    className="mysm:flex-col flex-row gap-12 grid mysm:grid-cols-1 grid-cols-2 justify-center w-full"
                                    orientation="horizontal"
                                >
                                    <div className="border border-solid border-[#314B66] flex flex-col gap-6 items-center justify-center p-6 mysm:px-5 rounded-[10px] w-auto">
                                        <div className="flex flex-row gap-4 items-center justify-start w-full">
                                            <Img
                                                className="h-12 w-12"
                                                src="images/img_call.svg"
                                                alt="call"
                                            />
                                            <div className="flex flex-col gap-2 justify-start">
                                                <Text
                                                    className="ml-1.5 mymd:ml-[0] text-base text-[#314b66]"
                                                    size="txtPoppinsMedium16Bluegray900"
                                                >
                                                    {t('call')}
                                                </Text>
                                                <Text
                                                    className="text-base text-[#314b66]"
                                                    size="txtPoppinsRegular16Bluegray900"
                                                >
                                                    00224 611 822 122
                                                </Text>
                                            </div>
                                        </div>
                                        <Button
                                            className="cursor-pointer font-semibold leading-[normal] text-base text-center w-[195px] text-[#314B66] bg-[#82bcfa]"
                                            shape="round"
                                            color="yellow_900_33"
                                            size="sm"
                                            variant="fill"
                                            onClick={() => window.open("tel:00224611822122")}
                                        >
                                            {t('call-now')}
                                        </Button>
                                    </div>
                                    <div className="border border-solid border-[#314B66] rounded-lg flex mysm:flex-1 flex-col gap-6 items-center justify-center p-6 mysm:px-5 w-[395px] mysm:w-full">
                                        <div className="flex flex-row gap-4 items-center justify-start mymd:pr-10 pr-12 mysm:pr-5 w-full">
                                            <Img
                                                className="h-12 w-12"
                                                src="images/img_mail.svg"
                                                alt="mail"
                                            />
                                            <div className="flex flex-col gap-2.5 items-start justify-start">
                                                <Text
                                                    className="text-base text-[#314b66]"
                                                    size="txtPoppinsRegular16Bluegray900"
                                                >
                                                    {t('e-mail')}
                                                </Text>
                                                <Text
                                                    className="text-base text-[#314b66]"
                                                    size="txtPoppinsRegular16Bluegray900"
                                                >
                                                    commercial.iloger@gmail.com
                                                </Text>
                                            </div>
                                        </div>
                                        <Button
                                            className="cursor-pointer font-semibold leading-[normal] text-base text-center w-[195px] bg-[#314B66] hover:bg-[#496f97]"
                                            shape="round"
                                            color="yellow_900_cc"
                                            size="sm"
                                            variant="fill"
                                            onClick={() => window.open("mailto:commercial.iloger@gmail.com")}
                                        >
                                            {t('email-now')}
                                        </Button>
                                    </div>
                                </List>
                            </div>
                            {/* <div className="flex mymd:flex-1 flex-col gap-[25px] items-start justify-start pb-[13px] px-[13px] w-[35%] mymd:w-full">
                                <Text
                                    className="ml-1.5 mymd:ml-[0] text-4xl mysm:text-[32px] mymd:text-[34px] text-[#314b66]"
                                    size="txtPoppinsSemiBold36"
                                >
                                    Award
                                </Text>
                                <Img
                                    className="h-[152px] mymd:h-auto ml-1.5 mymd:ml-[0] object-cover w-[57%] mysm:w-full"
                                    src="images/img_image9.png"
                                    alt="imageNine"
                                />
                            </div> */}
                        </div>
                        <div className="bg-[#314b66] flex mymd:flex-col flex-row font-roboto mymd:gap-10 items-center justify-between p-[31px] mysm:px-5 w-full">
                            <div className="flex flex-col items-center justify-end mymd:ml-[0] ml-[69px] p-[5px] mymd:px-5">
                                <Text
                                    className="mt-0.5 text-2xl mymd:text-[22px] text-green-50 mysm:text-xl"
                                    size="txtPoppinsSemiBold36"
                                >
                                    <span className="text-green-50 text-left font-semibold">
                                        iLoger-{" "}
                                    </span>
                                    <a
                                        href="#"
                                        className="text-green-50 text-left font-semibold underline"
                                    >
                                        Business Immobilier
                                    </a>
                                </Text>
                            </div>
                            <div className="flex flex-row gap-16 items-start justify-start mr-[69px] mymd:px-5 w-auto">
                                <Img
                                    className="h-6 w-6"
                                    src="images/img_camera.svg"
                                    alt="camera"
                                />
                                <Img
                                    className="h-6 w-6"
                                    src="images/img_facebook.svg"
                                    alt="facebook"
                                />
                                <Img
                                    className="h-6 w-6"
                                    src="images/img_reply.svg"
                                    alt="reply"
                                />
                                <Img
                                    className="h-6 w-6"
                                    src="images/img_solidbrandslinkedin.svg"
                                    alt="solidbrandslink"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    );
}

export default Landing;