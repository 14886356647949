import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/color.css";
import "./styles/font.css";
import "./styles/index.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/config';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCdSgWBkWCJCrSRpyE28QnlOxdPXGPqdQE",
  authDomain: "iloger-3b401.firebaseapp.com",
  projectId: "iloger-3b401",
  storageBucket: "iloger-3b401.appspot.com",
  messagingSenderId: "844083387282",
  appId: "1:844083387282:web:e5fc4332448e526baf3f4b",
  measurementId: "G-LCHT5922WF",
  fcmServerKey: "AAAAxIdKW5I:APA91bHfnuZ7USmp2Q3S_KTk-17EnK1XZaNdnOmDxnOG53FfW8Z2cuVvfK-Cd5rdD_b_kcHMt9KVfDz4LBnJStpWpJO5MYt7AxMXsvuEFF9kepF48D7m8Q4OqAQzFdW1vRhMCjJUTroP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
export const storage = getStorage(app);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
    <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



