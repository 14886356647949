/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useContext } from "react";
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import Loading from "../components/Loading";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "..";
import { ErrorHandlerComponent } from "../components/ErrorHandlerComponet";
import { toast } from "react-toastify";
import AppContext from "../AppContext";
import { useTranslation } from "react-i18next";


const Profile = () => {
    const {t} = useTranslation();

    useDocumentTitle(t('profile'));

    const context = useContext(AppContext);


    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchProfile = async () => {
        try {
            const docRef = doc(db, "users", context.user.id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setProfile(docSnap.data());
            } else {
                setError(t('account-not-found'));
                toast.error(t('account-not-found'));
            }
        } catch (error) {
            setError(error);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    
    useEffect(() => {
        fetchProfile();
    }, []);

    const settingInputChange = (e) => {
        setProfile({ ...profile, [e.target.name]: e.target.value });
    }


    const submitProfile = async (e) => {
        e.preventDefault();

        const loading = toast.loading(t('updating-profile'));

        try {
            const docRef = doc(db, "users", context.user.id);
            await updateDoc(docRef, profile);
            toast.success(t('profile-updated'));
        }
        catch (error) {
            toast.error(t('error-updating-profile'));
        }
        finally {
            toast.dismiss(loading);
        }
    }

    const [passwordData, setPasswordData] = useState({});

    const passwordInputChange = (e) => {
        setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
    }

    const submitPassword = async (e) => {
        e.preventDefault();

        if (passwordData.password !== passwordData.password_confirmation) {
            toast.error(t('password-and-confirm-password-does-not-match'));
            return;
        }

        const loading = toast.loading(t('updating-password'));

        try {
            const docRef = doc(db, "users", context.user.id);

            //get current password
            const docSnap = await getDoc(docRef);
            const currentPassword = docSnap.data().password;

            if (currentPassword !== passwordData.current_password) {
                toast.error(t('current-password-is-incorrect'));
                return;
            }


            await updateDoc(docRef, {password: passwordData.password});
            toast.success(t('password-updated'));
        }
        catch (error) {
            toast.error(t('error-updating-password'));
        }
        finally {
            toast.dismiss(loading);
        }

    }


    if (loading) {
        return (<Loading />);
    }

    if (error) {
        return (<ErrorHandlerComponent error={error} />);
    }


    return (
        <>

            <MainTemplate title={t('profile')}>

                {/* PROFILE */}
                <div className="mt-10">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">{t('personal-information')}</h3>
                                <p className="mt-1 text-sm text-gray-600">{t('this-is-your-basic-profile-info')}</p>
                            </div>
                        </div>
                        <div className="mt-5 md:col-span-2 md:mt-0">
                            <form onSubmit={submitProfile} method="POST">
                                <div className="overflow-hidden shadow sm:rounded-md">
                                    <div className="bg-white px-4 py-5 sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="full_name" className="block text-sm font-medium text-gray-700">
                                                    {t('full-name')}
                                                </label>
                                                <input
                                                    name='full_name'
                                                    value={profile.full_name || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="full_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John Doe" />
                                            </div>

                                            <div className="col-span-6">
                                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                    {t('email-address')}
                                                </label>
                                                <input
                                                    name='email'
                                                    defaultValue={profile.email || ""}
                                                    disabled
                                                    readOnly
                                                    type="email" className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                />
                                            </div>
                                            {/* city */}
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                                    {t('city')}
                                                </label>
                                                <input
                                                    name='city'
                                                    value={profile.city || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="city" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>
                                            {/* state */}
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                                                    {t('state')}
                                                </label>
                                                <input
                                                    name='state'
                                                    value={profile.state || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="state" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>
                                            {/* country */}
                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                                    {t('country')}
                                                </label>
                                                <input
                                                    name='country'
                                                    value={profile.country || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="country" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6">
                                                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                                    {t('address')}
                                                </label>
                                                <input
                                                    name='address'
                                                    value={profile.address || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('address')} />
                                            </div>


                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                        <button
                                            type="submit"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            {t('save')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                {/* CHANGE PASSWORD */}
                <div className="hidden sm:block" aria-hidden="true">
                    <div className="py-5">
                        <div className="border-t border-gray-200" />
                    </div>
                </div>

                <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">{t('change-password')}</h3>
                                <p className="mt-1 text-sm text-gray-600">{t('changing-password-requires-your-current-and-new-password')}</p>
                            </div>
                        </div>
                        <div className="mt-5 md:col-span-2 md:mt-0">
                            <form onSubmit={submitPassword} method="POST">
                                <div className="overflow-hidden shadow sm:rounded-md">
                                    <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="current_password" className="block text-sm font-medium text-gray-700">
                                                {t('current-password')}
                                            </label>
                                            <input
                                                name='current_password'
                                                value={passwordData.current_password || ""}
                                                onChange={passwordInputChange}
                                                type="password" id="current_password" style={{ fontFamily: 'Verdana' }} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('password')} required />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                {t('new-password')}
                                            </label>
                                            <input
                                                name='password'
                                                value={passwordData.password || ""}
                                                onChange={passwordInputChange}
                                                type="password" id="password" style={{ fontFamily: 'Verdana' }} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('password')} required />

                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="password_confirmation" className="block text-sm font-medium text-gray-700">
                                                {t('confirm-password')}
                                            </label>
                                            <input
                                                name='password_confirmation'
                                                value={passwordData.password_confirmation || ""}
                                                onChange={passwordInputChange}
                                                type="password" id="password_confirmation" style={{ fontFamily: 'Verdana' }} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('password')} required />

                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                        <button
                                            type="submit"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            {t('change-password')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </MainTemplate>

        </>
    );
}

export default Profile;