/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import BookingsTable from "../components/BookingsTable";
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "..";
import { helpers } from "../helpers";
import { useTranslation } from "react-i18next";
import AppContext from "../AppContext";


const Bookings = () => {
    const {t} = useTranslation();

    const {user} = useContext(AppContext);

    useDocumentTitle(t('bookings'));

    //faker properties
    const [bookings, setBookings] = useState([]);
    const [bookingsLoading, setBookingsLoading] = useState(true);
    const fetchBookings = async (client_name = "", id = "") => {
        setBookingsLoading(true);
        let bookings = [];
        //fetch bookings from firebase
        const bookingsRef = collection(db, 'bookings');

        var queries = [
            orderBy('created_at', 'desc')
        ];

        if (client_name && client_name.length > 0 && id.length === 0) {
            const searchWords = helpers.generateLiteSearchKeywords(client_name);
            queries.push(where('search_keywords', 'array-contains-any', searchWords));
        }

        if (id && id.length > 0 && client_name.length === 0) {
            queries.push(where('doc_number', 'in', [id, parseInt(id)]));
        }

        if (helpers.isHost(user)) {
            queries.push(where('host_id', '==', user.id));
        }

        var q = query(bookingsRef, ...queries);


        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                bookings.push({ ...doc.data(), id: doc.id });
            });
        });

        setBookings(bookings);
        setBookingsLoading(false);
    }


    useEffect(() => {
        fetchBookings();
    }, []);


    const onTableUpdated = (booking) => {
        fetchBookings();
    }



    const onSearchNameButtonClicked = () => {
        const client_name = document.getElementById('client_name').value;
        fetchBookings(client_name);
    }

    const onSearchIdButtonClicked = () => {
        const booking_id = document.getElementById('booking_id').value;
        fetchBookings("", booking_id);
    }






    return (
        <>
            <MainTemplate title={t('bookings')}>



                {/* <!-- search filter --> */}
                <div className="grid grid-cols-2">
                    {/* <!-- col-1 --> */}
                    <div></div>
                    {/* <!-- col-2 --> */}
                    <div className="flex space-x-2 items-end w-full" style={{ textAlign: "right" }}>
                        <div className="inline-block">
                            <div className="relative">
                                <input
                                    type="search"
                                    id="client_name"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            onSearchNameButtonClicked();
                                        }
                                    }}
                                    className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" 
                                    placeholder={t('search-name')} />
                                <button
                                    onClick={onSearchNameButtonClicked}
                                    type="button"
                                    className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                    <span className="sr-only">{t('search')}</span>
                                </button>
                            </div>
                        </div>

                        <div className="inline-block">
                            <div className="relative">
                                <input
                                    type="search"
                                    id="booking_id"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            onSearchIdButtonClicked();
                                        }
                                    }}
                                    className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" 
                                    placeholder={t('booking-id')} />
                                <button
                                    onClick={onSearchIdButtonClicked}
                                    type="button"
                                    className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                    <span className="sr-only">{t('search')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <BookingsTable bookings={bookings} loading={bookingsLoading} onTableUpdated={onTableUpdated} />


            </MainTemplate>
        </>
    );
};

export default Bookings;
