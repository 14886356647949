/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import { useState, useEffect } from "react";
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import FacilitiesTable from "../components/FacilitiesTable";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "..";
import { useTranslation } from "react-i18next";



const Facilities = () => {
    const {t} = useTranslation();
    useDocumentTitle(t('facilities'));

    const [facilities, setFacilities] = useState([]);
    const [facilitiesLoading, setFacilitiesLoading] = useState(false);
    const fetchFacilities =  async () => {
        setFacilitiesLoading(true);
        let facilities = [];
        //fetch bookings from firebase
        const facilitiesRef = collection(db, 'facilities');
        const q = query(facilitiesRef, orderBy('created_at', 'desc'));
        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                facilities.push({ ...doc.data(), id: doc.id });
            });
        });
        setFacilities(facilities);
        setFacilitiesLoading(false);
    };

    useEffect(() => {
        fetchFacilities();
    }, []);


    const onTableUpdated = (facility) => {
        fetchFacilities();
    }


    return (
        <>

            <MainTemplate title={t('facilities')}>


               

                {/* <!-- Facilities --> */}
                <FacilitiesTable facilities={facilities} onTableUpdated={onTableUpdated} loading={facilitiesLoading} />

                <br />
                <br />
                <br />
                <br />



            </MainTemplate>

        </>
    );
}

export default Facilities;