
import { useState, useEffect } from "react";
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import Loading from "../components/Loading";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "..";
import { ErrorHandlerComponent } from "../components/ErrorHandlerComponet";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Settings = () => {
    const {t} = useTranslation();

    useDocumentTitle(t('settings'));


    const [settings, setSettings] = useState({});

    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(null);

    const fetchSettings = async () => {
        try {
            const docRef = doc(db, "settings", "settings");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setSettings(docSnap.data());
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }

            setLoading(false);
        } catch (error) {
            setError(error);
        }
    }

    useEffect(() => {
        fetchSettings();
    }, []);

    const settingInputChange = (e) => {
        setSettings({ ...settings, [e.target.name]: e.target.value });
    }


    const submitSettings = async (e) => {
        e.preventDefault();

        const loading = toast.loading(t('updating-settings'));

        const docRef = doc(db, "settings", "settings");

        //update
        await updateDoc(docRef, settings).then(() => {
            toast.success(t('settings-updated-successfully'));
        }).catch((error) => {
            toast.error(error.message);
        });

        toast.dismiss(loading);
    }

    if (loading) {
        return (<Loading />);
    }

    if (error) {
        return (<ErrorHandlerComponent error={error} />);
    }

    return (
        <>

            <MainTemplate title={t('settings')}>

                <form onSubmit={submitSettings} method="POST">

                    {/* General Settings */}
                    <div className="mt-10">
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('company-information')}</h3>
                                    <p className="mt-1 text-sm text-gray-600">{t('this-is-the-basic-company-info')}</p>
                                </div>
                            </div>
                            <div className="mt-5 md:col-span-2 md:mt-0">
                                <div className="overflow-hidden shadow sm:rounded-md">
                                    <div className="bg-white px-4 py-5 sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="company_name" className="block text-sm font-medium text-gray-700">
                                                    {t('company-name')}
                                                </label>
                                                <input
                                                    name='company_name'
                                                    value={settings.company_name || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="company_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t('google-inc')} />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                    {t('email-0')}
                                                </label>
                                                <input
                                                    name='email'
                                                    value={settings.email || ""}
                                                    onChange={settingInputChange}
                                                    type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                                    {t('phone')}
                                                </label>
                                                <input
                                                    name='phone'
                                                    value={settings.phone || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                                    {t('address')}
                                                </label>
                                                <input
                                                    name='address'
                                                    value={settings.address || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                                    {t('city')}
                                                </label>
                                                <input
                                                    name='city'
                                                    value={settings.city || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="city" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                                                    {t('state')}
                                                </label>
                                                <input
                                                    name='state'
                                                    value={settings.state || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="state" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                                    {t('country')}
                                                </label>
                                                <input
                                                    name='country'
                                                    value={settings.country || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="country" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="zip_code" className="block text-sm font-medium text-gray-700">
                                                    {t('zip-code')}
                                                </label>
                                                <input
                                                    name='zip_code'
                                                    value={settings.zip_code || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="zip_code" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="facebook" className="block text-sm font-medium text-gray-700">
                                                    {t('facebook')}
                                                </label>
                                                <input
                                                    name='facebook'
                                                    value={settings.facebook || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="facebook" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="twitter" className="block text-sm font-medium text-gray-700">
                                                    {t('twitter')}
                                                </label>
                                                <input
                                                    name='twitter'
                                                    value={settings.twitter || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="twitter" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="instagram" className="block text-sm font-medium text-gray-700">
                                                    {t('instagram')}
                                                </label>
                                                <input
                                                    name='instagram'
                                                    value={settings.instagram || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="instagram" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="linkedin" className="block text-sm font-medium text-gray-700">
                                                    {t('linkedin')}
                                                </label>
                                                <input
                                                    name='linkedin'
                                                    value={settings.linkedin || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="linkedin" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="youtube" className="block text-sm font-medium text-gray-700">
                                                    {t('youtube')}
                                                </label>
                                                <input
                                                    name='youtube'
                                                    value={settings.youtube || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="youtube" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="pinterest" className="block text-sm font-medium text-gray-700">
                                                    {t('pinterest')}
                                                </label>
                                                <input
                                                    name='pinterest'
                                                    value={settings.pinterest || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="pinterest" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="google" className="block text-sm font-medium text-gray-700">
                                                    {t('google')}
                                                </label>
                                                <input
                                                    name='google'
                                                    value={settings.google || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="google" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* App Settings */}
                    <div className="mt-10">
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('app-settings')}</h3>
                                    <p className="mt-1 text-sm text-gray-600">{t('this-is-the-basic-app-settings')}</p>
                                </div>
                            </div>
                            <div className="mt-5 md:col-span-2 md:mt-0">
                                <div className="overflow-hidden shadow sm:rounded-md">
                                    <div className="bg-white px-4 py-5 sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="current_app_version" className="block text-sm font-medium text-gray-700">
                                                    {t('current-app-version')}
                                                </label>
                                                <input
                                                    name='current_app_version'
                                                    value={settings.current_app_version || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="current_app_version" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:opacity-50" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="latest_app_version" className="block text-sm font-medium text-gray-700">
                                                    {t('latest-app-version')}
                                                </label>
                                                <input
                                                    name='latest_app_version'
                                                    value={settings.latest_app_version || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="latest_app_version" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="app_update_link" className="block text-sm font-medium text-gray-700">
                                                    {t('app-update-link')}
                                                </label>
                                                <input
                                                    name='app_update_link'
                                                    value={settings.app_update_link || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="app_update_link" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="app_update_message" className="block text-sm font-medium text-gray-700">
                                                    {t('app-update-message')}
                                                </label>
                                                <textarea
                                                    name='app_update_message'
                                                    value={settings.app_update_message || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="app_update_message" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="app_update_force" className="block text-sm font-medium text-gray-700">
                                                    {t('app-update-force')}
                                                </label>
                                                <select
                                                    name='app_update_force'
                                                    value={settings.app_update_force || "0"}
                                                    onChange={settingInputChange}
                                                    id="app_update_force" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Maintenace Settings */}
                    <div className="mt-10">
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('maintenance-settings')}</h3>
                                    <p className="mt-1 text-sm text-gray-600">{t('this-is-total-system-maintenance-settings')}</p>
                                </div>
                            </div>
                            <div className="mt-5 md:col-span-2 md:mt-0">
                                <div className="overflow-hidden shadow sm:rounded-md">
                                    <div className="bg-white px-4 py-5 sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="maintenance_message" className="block text-sm font-medium text-gray-700">
                                                    {t('maintenance-message')}
                                                </label>
                                                <textarea
                                                    name='maintenance_message'
                                                    value={settings.maintenance_message || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="maintenance_message" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="maintenance" className="block text-sm font-medium text-gray-700">
                                                    {t('maintenance')}
                                                </label>
                                                <select
                                                    name='maintenance'
                                                    value={settings.maintenance || "0"}
                                                    onChange={settingInputChange}
                                                    id="maintenance" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select>
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label htmlFor="maintenance_until" className="block text-sm font-medium text-gray-700">
                                                    {t('maintenance-until')}
                                                </label>
                                                <input
                                                    name='maintenance_until'
                                                    value={settings.maintenance_until || ""}
                                                    onChange={settingInputChange}
                                                    type="datetime-local"
                                                    id="maintenance_until" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full disabled:opacity-50 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Save button layout */}
                    <div className="mt-10">
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('save-settings')}</h3>
                                    <p className="mt-1 text-sm text-gray-600">{t('click-the-button-below-to-save-the-settings')}</p>
                                </div>
                            </div>
                            <div className="mt-5 md:col-span-2 md:mt-0">
                                <div className="overflow-hidden shadow sm:rounded-md">
                                    <div className="bg-white px-4 py-5 sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                    {t('save-settings-0')}
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </form>

            </MainTemplate>

        </>
    );
}

export default Settings;