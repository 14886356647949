/* eslint-disable eqeqeq */

import { toast } from "react-toastify";
import { db, firebaseConfig } from ".";
import axios from "axios";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import emailjs from "emailjs-com";

export const helpers = {
  codeMessage: function (data) {
    if (data.code == "validation-error") {
      data.messages.forEach((message) => {
        toast(message);
      });
    } else {
      toast(data.message);
    }
  },
  nFormatter: function (num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  },
  dynamicColors: function (opacity = 1) {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
  },
  capitalizeFirstLetter: function (string) {
    return string == null && string == ""
      ? ""
      : string.charAt(0).toUpperCase() + string.slice(1);
  },
  capitalize: function (string) {
    return string.replace(/^[a-z]/, (match) => match.toUpperCase());
  },
  money: function (price) {
    let formatter = Intl.NumberFormat("en-US");
    return formatter.format(String(price));
  },
  statusColor: function (status) {
    var color = "black";
    switch (status) {
      case "active":
      case "paid":
      case "completed":
        color = "#689142";
        break;
      case "inactive":
        color = "#7F7F7F";
        break;
      case "suspended":
        color = "#FF2200";
        break;
      case "pending":
        color = "#D6C410";
        break;
      case "canceled":
      case "cancelled":
        color = "#EC050C";
        break;
      case "processing":
        color = "#E3B7EB";
        break;
      case "accepted":
        color = "#74B801";
        break;
      default:
        break;
    }
    return color;
  },
  geoDistance: function (lat1, lon1, lat2, lon2) {
    function toRad(val) {
      return (val * Math.PI) / 180;
    }

    var R = 6371; // Radius of the Earth in km

    var dLat = toRad(lat2 - lat1); // Javascript functions in radians
    var dLon = toRad(lon2 - lon1);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  },
  defaultThumbnail: function (type = "", name = "") {
    if (type == "avatar" && name != "") {
      return `https://ui-avatars.com/api/?name=${name}&background=random&color=ffffff&size=150`;
    }
    return "https://via.placeholder.com/150";
  },
  generateSearchKeywords: function (title) {
    var keywords = [];
    if (
      title == null ||
      title == "" ||
      title == undefined ||
      title.length < 1
    ) {
      return keywords;
    }
    var words = title.toLowerCase().split(" ");
    words.forEach((word) => {
      var temp = "";
      for (let i = 0; i < word.length; i++) {
        temp += word[i];
        keywords.push(temp);
      }
    });
    return keywords;
  },
  generateLiteSearchKeywords: function (title) {
    var keywords = [];
    var words = title.toLowerCase().split(" ");
    words.forEach((word) => {
      if (word.length > 0) {
        keywords.push(word);
      }
    });
    return keywords;
  },
  getSlug: function (title) {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  },
  getFirstImage: function (image, type = "", name = "") {
    if (image == null || image == "") {
      return this.defaultThumbnail(type, name);
    } else if (Array.isArray(image)) {
      const img = image[0];
      //if img is an object
      if (typeof img === "object" && !Array.isArray(img)) {
        return img.url ?? img.image ?? this.defaultThumbnail(type, name);
      }
      return img ?? this.defaultThumbnail(type, name);
    }

    return image;
  },
  generateRandomString: function (length = 10) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;

    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  },
  pushNotificationToToken: async function (token, notification, data = null) {
    const fcm_url = "https://fcm.googleapis.com/fcm/send";
    const fcm_key = firebaseConfig.fcmServerKey;
    const body = {
      to: token,
      notification: notification,
      data: data ?? {},
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: "key=" + fcm_key,
    };

    await axios
      .post(fcm_url, body, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  pushNotificationToTopic: async function (topic, notification, data = null) {
    const fcm_url = "https://fcm.googleapis.com/fcm/send";
    const fcm_key = firebaseConfig.fcmServerKey;
    const body = {
      to: "/topics/" + topic,
      notification: notification,
      data: data ?? {},
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: "key=" + fcm_key,
    };

    await axios
      .post(fcm_url, body, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  sendMail: async function (to, subject, body) {
    const company_name = "iLoger";
    const contact_email = "keyuser.iloger@gmail.com";

    const emailjsConfig = {
      serviceId: "service_q0xxo7h",
      templateId: "template_v5mp37a",
      userId: "iH2hOmmNfrVLTfOTy",
    };

    var result = await emailjs.send(
      emailjsConfig.serviceId,
      emailjsConfig.templateId,
      {
        to: to,
        subject: subject,
        body: body,
        company_name: company_name,
        contact_email: contact_email,
      },
      emailjsConfig.userId
    );

    return result;
  },
  isAdmin: function (user) {
    return user.role == "admin";
  },
  isSuperAdmin: function (user) {
    return user.role == "super-admin";
  },
  isAdministrator: function (user) {
    return user.role == "admin" || user.role == "super-admin";
  },
  isHost(user) {
    return user.role == "host";
  },
  getLastDocumentNumber: async function (collection) {
    const ref = doc(db, "counters", collection);
    const document = await getDoc(ref);
    if (document.exists) {
      const data = document.data();
      return data["last_document_number"] || 0;
    } else {
      return 0;
    }
  },
  getNextDocumentNumber: async function (collection, updateNext = true) {
    const id = await this.getLastDocumentNumber(collection);
    const next = id + 1;

    if (updateNext) {
      const ref = doc(db, "counters", collection);
      await updateDoc(ref, { last_document_number: next });
    }

    return next;
  },
  hasValue: function (value) {
    return (
      value != null &&
      value != undefined &&
      value != "" &&
      value != 0 &&
      value != "0" &&
      value != false &&
      value != "false" &&
      isNaN(value) == false
    );
  },
  log: async function (user, type, message, data = null) {
    //add to firebase logs
    const ref = collection(db, "logs");
    const log = {
      user_id: user.id,
      user: user,
      type: type,
      message: message,
      data: data,
      created_at: Timestamp.now(),
    };
    await addDoc(ref, log);
  },
};

//
