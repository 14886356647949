/* eslint-disable eqeqeq */

import SkyeModal from "../../components/SkyeModal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const VerifyCodeModal = ({ showModal, setShowModal, code, setCode, openResetPasswordModal }) => {

    const { t } = useTranslation();

    const [inputCode, setInputCode] = useState("");

    const verifyCode = async () => {
        if (inputCode === "") {
            toast.error(t('code-is-required'));
            return;
        }

        if (inputCode != code) {
            toast.error(t('invalid-code'));
            return;
        }

        toast.success(t('code-verified'));
        setShowModal(false);
        openResetPasswordModal();
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        verifyCode();
    }


    return (
        <>
            <SkyeModal title={t('verify')} size="max-w-sm" flex={false} showModal={showModal} setShowModal={setShowModal}>
                <form autoComplete="off" className="space-y-6" id="type-edit-form" onSubmit={onFormSubmit} method="POST" encType="multipart/form-data">
                    {/*body*/}
                    <div className="relative px-6 pt-6 pb-2 flex-auto">

                        <div>
                            <label htmlFor="input_code" className="block text-sm font-medium text-gray-900 dark:text-gray-300">{t('enter-code-sent-to-your-email')}</label>
                            <input
                                name="input_code"
                                value={inputCode || ""}
                                onChange={(e) => setInputCode(e.target.value)}
                                type="number" id="input_code" className="bg-gray-50 text-center border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder={t('code')} required />
                        </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <span id="submit-text">{t('verify')}</span>
                        </button>
                    </div>
                </form>
            </SkyeModal>
        </>
    );
}

export default VerifyCodeModal;