// import { Outlet, Link } from "react-router-dom";
import logo from '../assets/images/logo2.png';
import { useTranslation } from 'react-i18next';

function Loader() {
    const { t } = useTranslation();
    return (
        <>
            <main className="h-screen w-full flex flex-col justify-center items-center bg-white">
                <h1 className="text-9xl font-extrabold text-black tracking-widest">
                    <img src={logo} alt="logo" className="animate-bounce w-20 h-20" />
                </h1>
                <div className="bg-[#FF6A3D] px-2 text-sm rounded">
                    {t('loading')}
                </div>
            </main>
        </>
    );
}

export default Loader;