
/** 
 * idea from: https://www.creative-tim.com/learning-lab/tailwind-starter-kit/documentation/react/modals/small
 * size: max-w-3xl, max-w-2xl, max-w-xl, max-w-lg, max-w-md, max-w-sm, max-w-xs
 * title: string
 * showModal: boolean
 * setShowModal: function
 * flex: boolean
 * 
*/
const SkyeModal = ({ children, header=null, title, size = "max-w-3xl", flex = true, showModal, setShowModal }) => {
    return (<>
        {showModal ? (
            <>
                <div
                    className={`justify-center items-center ${flex ? "flex" : ""} overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`}
                >
                    {/* use size vairable as class */}
                    <div className={`relative w-auto my-6 mx-auto ${size}`}>
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-2xl">
                                    {title}
                                </h3>
                                <div className="w-full px-5">
                                    {header}
                                </div>
                                <button
                                    className="p-1 ml-auto border-0 text-black loat-right text-3xl leading-none font-semibold outline-none focus:outline-none hover:bg-gray-200 rounded"
                                    onClick={() => setShowModal(false)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon w-6 h-6 icon-tabler icon-tabler-x" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M18 6l-12 12" />
                                        <path d="M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            {/* body and footer */}
                            {children}
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null}
    </>);
}

export default SkyeModal;