import React from "react";

const sizeClasses = {
  txtRubikSemiBold64: "font-rubik font-semibold",
  txtPoppinsBold28: "font-bold font-poppins",
  txtPoppinsLight16: "font-light font-poppins",
  txtJosefinSansRegular24: "font-josefinsans font-normal",
  txtRubikSemiBold27: "font-rubik font-semibold",
  txtPoppinsRegular16Bluegray900: "font-normal font-poppins",
  txtPoppinsRegular16: "font-normal font-poppins",
  txtPoppinsSemiBold24: "font-poppins font-semibold",
  txtPoppinsSemiBold36: "font-poppins font-semibold",
  txtPoppinsSemiBold16: "font-poppins font-semibold",
  txtPoppinsMedium16Bluegray900: "font-medium font-poppins",
  txtPoppinsSemiBold56: "font-poppins font-semibold",
  txtJosefinSansBold36: "font-bold font-josefinsans",
  txtRobotoRomanSemiBold24: "font-roboto font-semibold",
  txtPoppinsMedium16: "font-medium font-poppins",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
