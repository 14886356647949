/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../..";
import useDocumentTitle from "../../hooks/userDocumentTitle";
import NoPage from "../NoPage";


const PostPage = () => {
    useDocumentTitle('Empty Page');

    const [pageLoading, setPageLoading] = useState(false);
    const [pageError, setPageError] = useState(false);
    const [post, setPost] = useState({});

    //get slug from url format: https://iloger.com/post/hello-world
    const slug = window.location.pathname.split('/')[2] ?? '';

    useEffect(() => {
        fetchPost();
    }, []);


    const fetchPost = async () => {
        setPageLoading(true);
        setPageError(false);
        //fetch post from firebase where slug = slug
        const postRef = collection(db, "posts");
        const q =  query(postRef, where("slug", "==", slug));

        await getDocs(q).then((snapshot) => {
            if (snapshot.docs.length > 0) {
                snapshot.forEach((doc) => {
                    setPost({ ...doc.data(), id: doc.id });
                });
            } else {
                setPageError(true);
            }
        });

        
        setPageLoading(false);
    };

    if (pageLoading) {
        //center loading with tailwindcss
        return (
            <>
                <div className="flex justify-center items-center h-screen">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                </div>
            </>
        );
    }


    if (pageError) {
        return (
            <>
                <NoPage />
            </>
        );
    }


    return (
        //render post content
        <>
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-8/12 px-4">
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-8/12 px-4 py-5">
                                <h1 className="text-3xl font-semibold">{post.title}</h1>
                                <div className="py-6">
                                    <div className="text-lg leading-relaxed text-gray-600">
                                        {/* to html view */}
                                        <div dangerouslySetInnerHTML={{ __html: post.content }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default PostPage;