import { useContext, useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import { Timestamp, addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../index";
import { toast } from "react-toastify";
import { helpers } from "../../helpers";
import { useTranslation } from "react-i18next";
import AppContext from "../../AppContext";

const NotificationModal = ({ showModal, setShowModal, notification, setNotification, onModalUpdated }) => {

    const {t} = useTranslation();

    const {user} = useContext(AppContext);

    const [notificationData, setNotificationData] = useState(notification ?? {});

    useEffect(() => {
        setNotificationData(notification);
    }, [notification]);


    const onInputChanged = (e) => {
        setNotificationData({ ...notificationData, [e.target.name]: e.target.value });
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();

        const loading = toast.loading(t('please-wait'));

        let formData = { ...notificationData };

        //push notification
        const topic = "general";
        await helpers.pushNotificationToTopic(topic,
            {
                title: formData.title,
                body: formData.content,
            },
            {
                "navigation": "notification",
            }
        );

        if (formData.id) { //update
            //remove id, created_at
            delete formData.id;
            delete formData.created_at;
            formData.updated_at = Timestamp.now();

            //add to firebase
            const notificationRef = doc(db, "notifications", notificationData.id);
            await updateDoc(notificationRef, formData).then(async () => {
                //get the updated data
                const docSnap = await getDoc(notificationRef);
                if (docSnap.exists()) {
                    setNotification({ ...docSnap.data(), id: docSnap.id });
                    setNotificationData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success(t('notificaton-re-pushed-successfully'));
                } else {
                    toast.error(t('failed-to-re-push'));
                }
            }).catch((error) => {
                toast.error(t('failed-to-re-push'));
            });

            helpers.log(user, "update", `notification <b>(${notificationData.title})</b> re-pushed`);

        } else { //add
            formData.created_at = Timestamp.now();
            formData.updated_at = Timestamp.now();

            const notificationsRef = collection(db, "notifications");
            await addDoc(notificationsRef, formData).then(async (docRef) => {
                //get the added data
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setNotification({ ...docSnap.data(), id: docSnap.id });
                    setNotificationData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success(t('notification-pushed-successfully'));
                } else {
                    toast.error(t('failed-to-push'));
                }
            }).catch((error) => {
                toast.error(t('failed-to-push'));
            });

            helpers.log(user, "create", `notification <b>(${notificationData.title})</b> pushed`);

        }

        toast.dismiss(loading);
        setShowModal(false);
    }

    return (
        <>
            <SkyeModal title={notificationData.id ? t('notification') : t('new-notification')} size="max-w-sm" flex={false} showModal={showModal} setShowModal={setShowModal}>
                <form autoComplete="off" className="space-y-6" id="notification-edit-form" onSubmit={onFormSubmit} method="POST" encType="multipart/form-data">
                    {/*body*/}
                    <div className="relative p-6 flex-auto">

                        <div>
                            <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{t('title')} ( 🥳 {t('you-can-paste-any-emoji')} 🥳 ) </label>
                            <input
                                name="title"
                                value={notificationData.title || ""}
                                onChange={onInputChanged}
                                type="text" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder={t('notification-title')} required />
                        </div>

                        <div>
                            <label htmlFor="content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{t('content')} </label>
                            <textarea
                                name="content"
                                value={notificationData.content || ""}
                                onChange={onInputChanged}
                                id="content" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder={t('notification-content')} required></textarea>
                        </div>

                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <span id="submit-text">{notificationData.id ? t('re-push-notification') : t('push-notification')}</span>
                        </button>
                    </div>
                </form>
            </SkyeModal>
        </>
    );
}

export default NotificationModal;