/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import { useState, useEffect } from "react";
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "..";
import { useTranslation } from "react-i18next";
import LogsTable from "../components/LogsTable";



const Logs = () => {
    const {t} = useTranslation();

    useDocumentTitle(t('logs'));

    const [logs, setLogs] = useState([]);
    const [logsLoading, setLogsLoading] = useState(false);

    const fetchLogs =  async () => {
        setLogsLoading(true);
        let logs = [];
        //fetch logs from firebase, order by date created
        const logsRef = collection(db, 'logs');
        const q = query(logsRef, orderBy('created_at', 'desc'));
        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                logs.push({ ...doc.data(), id: doc.id });
            });
        });
        setLogs(logs);
        setLogsLoading(false);
    };


    useEffect(() => {
        fetchLogs();
    }, []);

    const onTableUpdated = (amenity) => {
        fetchLogs();
    }


    return (
        <>

            <MainTemplate title={t('logs')}>


                {/* <!-- logs --> */}
                <LogsTable logs={logs} onTableUpdated={onTableUpdated} loading={logsLoading} />

                <br />
                <br />
                <br />
                <br />



            </MainTemplate>

        </>
    );
}

export default Logs;