import { useContext, useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../index";
import { toast } from "react-toastify";
import { helpers } from "../../helpers";
import { useTranslation } from "react-i18next";
import AppContext from "../../AppContext";


const AmenityModal = ({ title = "New Amenity", showModal, setShowModal, amenity, setAmenity, onModalUpdated }) => {

    const [amenityData, setAmenityData] = useState(amenity ?? {});

    const {t} = useTranslation();

    const {user} = useContext(AppContext);

    useEffect(() => {
        setAmenityData(amenity);
    }, [amenity]);

    const onInputChanged = (e) => {
        setAmenityData({ ...amenityData, [e.target.name]: e.target.value });
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();

        const loading = toast.loading(t('please-wait'));

        let formData = { ...amenityData };
        if (formData.id) { //update
            //remove id, created_at
            delete formData.id;
            delete formData.created_at;
            delete formData.slug;
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.name);

            //add to firebase
            const amenityRef = doc(db, "amenities", amenityData.id);
            await updateDoc(amenityRef, formData, { merge: true }).then(async () => {
                //get the updated data
                const docSnap = await getDoc(amenityRef);
                if (docSnap.exists()) {
                    setAmenity({ ...docSnap.data(), id: docSnap.id });
                    setAmenityData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success(t('updated-successfully'));
                } else {
                    toast.error(t('failed-to-update'));
                }
            }).catch((error) => {
                toast.error(t('failed-to-update'));
            });

            helpers.log(user, "update", `amenity <b>(${amenityData.name})</b> updated`);

        } else { //add
            formData.created_at = Timestamp.now();
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.name);
            formData.slug = helpers.getSlug(formData.name);

            const amenitiesRef = collection(db, "amenities");

            //add timestamp to slug if slug already exists
            const slugQuery = query(amenitiesRef, where("slug", "==", formData.slug));
            const slugQuerySnapshot = await getDocs(slugQuery);
            if (slugQuerySnapshot.size > 0) {
                formData.slug += `-${formData.created_at.seconds}`;
            }

            //add to firebase
            const docRef = await addDoc(amenitiesRef, formData);
            if (docRef) {
                //get the updated data
                const amenityRef = doc(db, "amenities", docRef.id);
                const docSnap = await getDoc(amenityRef);
                if (docSnap.exists()) {
                    setAmenity({ ...docSnap.data(), id: docSnap.id });
                    setAmenityData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                } else {
                    toast.error(t('failed-to-add'));
                }
            } else {
                toast.error(t('failed-to-add'));
            }

            helpers.log(user, "add", `amenity <b>(${amenityData.name})</b> added`);
        }

        toast.dismiss(loading);
        setShowModal(false);
    }

    return (
        <>
            <SkyeModal title={amenityData.id ? "Amenity" : "New Amenity"} size="max-w-sm" flex={false} showModal={showModal} setShowModal={setShowModal}>
                <form autoComplete="off" className="space-y-6" id="amenity-edit-form" onSubmit={onFormSubmit} method="POST" encType="multipart/form-data">
                    {/*body*/}
                    <div className="relative px-6 pt-6 pb-2 flex-auto">

                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-900 dark:text-gray-300">{t('name')}</label>
                            <input
                                name="name"
                                value={amenityData.name || ""}
                                onChange={onInputChanged}
                                type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder={t('name')} required />
                        </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <span id="submit-text">{amenityData.id ? t('update') : t('add')}</span>
                        </button>
                    </div>
                </form>
            </SkyeModal>
        </>
    );
}

export default AmenityModal;